<template>
  <modal
    :name="name"
    height="auto"
    :scrollable="true"
  >
    <UIButton
      icon="close-big"
      class="close"
      @click="close"
    />

    <slot name="header">
      <div class="header">
        <img
          class="icon"
          :src="iconUrl"
          :alt="$t(titleI18nCode)"
        >
        <div>
          <h3 class="title">
            {{ $t(titleI18nCode) }}
          </h3>
          <div class="description">
            {{ $t(descriptionI18nCode) }}
          </div>
        </div>
      </div>
    </slot>

    <div class="content">
      <slot />
    </div>
  </modal>
</template>

<script>
import UIButton from '@/components/ui/UIButton/UIButton'

export default {
  name: 'UIModal',

  components: { UIButton },

  props: {
    name: {
      type: String,
      require: true,
    },
    iconUrl: {
      type: String,
      default: null,
    },
    titleI18nCode: {
      type: String,
      default: null,
    },
    descriptionI18nCode: {
      type: String,
      default: null,
    },
  },

  methods: {
    close() {
      this.$modal.hide(this.name)
    },
  },
}
</script>

<style scoped lang="sass">
@import "./style"
</style>
