<template>
  <div class="row no-gutters justify-content-center h-100">
    <div class="col-sm-12 col-md-8 col-lg-5 col-xl-5">
      <BQdtCard
        name="analyticsDim"
        type="top-menu-item"
      />
    </div>
    <div class="col-sm-12 col-md-8 col-lg-5 col-xl-5">
      <BQdtCard
        name="analyticsMes"
        type="top-menu-item"
      />
    </div>
  </div>
</template>

<script>
import { BQdtCard } from '@/components/blocks'

export default {
  name: 'CustomsValueTopMenu',

  components: { BQdtCard },
}
</script>
