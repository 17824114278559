<template>
  <div
    class="layout"
    :class="classes"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'UiLayout',

  computed: {
    classes() {
      return {
        'layout-has-sidebar': this.hasSidebar,
      }
    },
    hasSidebar() {
      return this.checkHasSlot('UiLayoutSidebar')
    },
  },

  methods: {
    checkHasSlot(slotName) {
      const slots = this.$slots.default
      return slots?.some(slot => {
        return slot.componentOptions?.tag === slotName
      })
    },
  },
}
</script>

<style scoped lang="sass">
@import "./style"
</style>
