<template>
  <aside
    class="layout-sidebar"
    :class="classes"
  >
    <div class="layout-sidebar-wrapper">
      <div class="layout-sidebar-menu-wrapper">
        <slot
          :collapsed="isCollapsedSidebar"
          :toggleCollapsed="toggleCollapsed"
        />
      </div>

      <UIButton
        class="layout-sidebar-trigger"
        icon="arrow-double-right-big"
        @click="toggleCollapsed"
      />
    </div>
  </aside>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

import UIButton from '@/components/ui/UIButton/UIButton'
import { BREAKPOINTS } from '@/constants'

export default {
  name: 'UiLayoutSidebar',

  components: {
    UIButton,
  },

  computed: {
    ...mapGetters('common', [
      'isCollapsedSidebar',
    ]),
    classes() {
      return {
        'layout-sidebar-collapsed': this.isCollapsedSidebar,
      }
    },
  },

  beforeMount() {
    if (window.innerWidth < BREAKPOINTS.DESKTOP) {
      this.setCollapsedSidebar(true)
    }
  },

  methods: {
    ...mapMutations('common', [
      'setCollapsedSidebar',
    ]),
    toggleCollapsed() {
      this.setCollapsedSidebar(!this.isCollapsedSidebar)
    },
  },
}
</script>

<style scoped lang="sass">
@import "./style"
</style>
