<template>
  <div
    v-if="show"
    class="radio-group"
  >
    <div
      v-for="option in options"
      :key="option.value"
      class="radio-group-item"
      :class="{
        active: option.value === value
      }"
      @click="handleClick(option.value)"
    >
      {{ option.label }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'UIRadioGroup',

  props: {
    value: {
      type: String,
      default: null,
    },
    options: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    show() {
      return this.options.length
    },
  },

  methods: {
    handleClick(value) {
      this.$emit('input', value)
      this.$emit('change', value)
    },
  },
}
</script>

<style scoped lang="sass">
@import "./style"
</style>
