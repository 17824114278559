<template>
  <BErrorPageTemplate />
</template>

<script>
import { BErrorPageTemplate } from '@/components/blocks'

export default {
  name: 'Page404',

  components: { BErrorPageTemplate },
}
</script>
