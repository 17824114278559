<template>
  <i
    class="icon"
    :class="classes"
  />
</template>

<script>
import helpers from '@/helpers'

export default {
  name: 'UIIcon',

  props: {
    type: {
      type: String,
      required: true,
      default: null,
      validator: helpers.validate.iconType,
    },
  },

  computed: {
    classes() {
      return {
        [`icon-${this.type}`]: true,
      }
    },
  },
}
</script>

<style scoped lang="sass">
@import "./style"
</style>
