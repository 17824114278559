<template>
  <UIRadioGroup
    :value="formValues.timeInterval"
    :options="optionsData"
    @change="handleChange"
  />
</template>

<script>
import { mapGetters } from 'vuex'
import { UIRadioGroup } from '@/components/ui'
import { updateOpenDataValuesMixin } from '@/mixins'

export default {
  name: 'RadioTimeInterval',

  components: { UIRadioGroup },

  mixins: [updateOpenDataValuesMixin],

  computed: {
    ...mapGetters('openData', [
      'options',
    ]),
    optionsData() {
      const { lang } = this.$route.params

      return this.options?.timeIntervals?.map(option => ({
        label: option[`name${ lang.toUpperCase()}`],
        value: option.value,
      }))
    },
  },

  methods: {
    handleChange(value) {
      this.setFormValue({
        name: 'timeInterval',
        value,
      })
    },
  },
}
</script>
