export const TRADE_ROUTE_NAMES = {
  INDEX: 'trade',
  HOME: 'tradeHome',
  PAYMENTS: 'tradePayments',
  IMPORT_EXPORT: 'tradeImportExport',
  BALANCE: 'tradeBalance',
  DIMENSIONS: 'tradeDimensions',
  RATINGS: 'tradeRatings',
  COMPARISON: 'tradeComparison',
  VALUE_OF_GOODS_HS_CODE: 'tradeValueOfGoodsHSCode',
  CUSTOMS_VALUE: 'tradeCustomsValue',
}

export const COMPLAINTS_ROUTE_NAMES = {
  INDEX: 'complaints',
  HOME: 'complaintsHome',
  RATINGS: 'complaintsRatings',
  ANALYTICS: 'complaintsAnalytics',
}

export const OTHER_ROUTE_NAMES = {
  OPEN_DATA: 'openData',
  ERROR_404: 'error-404',
}

export const ROUTE_NAMES = {
  TRADE: TRADE_ROUTE_NAMES,
  COMPLAINTS: COMPLAINTS_ROUTE_NAMES,
  ...OTHER_ROUTE_NAMES,
}
