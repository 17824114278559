export const BUTTON_TYPES = {
  PRIMARY: 'primary',
  LINK: 'link',
  SECONDARY: 'secondary',
  WHITE: 'white',
}

export const BUTTON_SIZES = {
  MEDIUM: 'medium',
  SMALL: 'small',
}
