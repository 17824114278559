<template>
  <UIButton
    class="link"
    :class="classes"
    @click="handleClickLink"
  >
    <UIIcon
      v-if="icon"
      :type="icon"
    />
    <span class="text">
      {{ $t(i18nCode) }}
    </span>

    <UIButton
      v-if="hasSubMenu"
      class="button-toggle-sub-menu"
      :class="iconClasses"
      icon="arrow-down-small-filled"
      size="small"
      @click.stop="handleClickArrow"
    />
  </UIButton>
</template>

<script>
import { UIIcon, UIButton } from '@/components/ui'
import helpers from '@/helpers'
import { ROUTE_NAMES } from '@/constants'

export default {
  name: 'SidebarMenuItem',

  components: { UIButton, UIIcon },

  props: {
    routerName: {
      type: String,
      required: true,
      validator: helpers.validate.routeName,
    },
    icon: {
      type: String,
      validator: helpers.validate.iconType,
      default: null,
    },
    i18nCode: {
      type: String,
      default: null,
    },
    hasSubMenu: {
      type: Boolean,
    },
    hasSubMenuCollapsed: {
      type: Boolean,
    },
  },

  computed: {
    classes() {
      const activeRouterNames = this.$route.matched.map(route => route.name)
      const {
        TRADE: {
          INDEX: tradeIndex,
          HOME: tradeHome,
        },
        COMPLAINTS: {
          INDEX: complaintsIndex,
          HOME: complaintsHome,
        },
      } = ROUTE_NAMES

      const activeTradeIndex = activeRouterNames.includes(tradeIndex)
        && this.routerName === tradeHome

      const activeComplaintsIndex = activeRouterNames.includes(complaintsIndex)
        && this.routerName === complaintsHome

      return {
        active: activeRouterNames.includes(this.routerName)
          || activeTradeIndex
          || activeComplaintsIndex,
      }
    },
    iconClasses() {
      return {
        active: this.hasSubMenuCollapsed,
      }
    },
  },

  methods: {
    handleClickLink() {
      if (this.$route.name === this.routerName) return

      this.$router.push({
        name: this.routerName,
      })
      this.$emit('click')
    },
    handleClickArrow() {
      this.$emit('toggle-sidebar')
    },
  },
}
</script>

<style scoped lang="sass">
@import "./style"
</style>
