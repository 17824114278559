import {
  apiCustomBackLocalization,
  apiExportBiData,
} from '@/services/api/instances'

import language from '@/services/api/language'
import openData from '@/services/api/openData'

const repositories = {
  language: language(apiCustomBackLocalization),
  openData: openData(apiExportBiData),
}

const api = {
  // method is used by Vue.use
  install: Vue => {
    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$api = repositories
  },

  // for use outside Vue
  ...repositories,
}

export default api
