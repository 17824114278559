<template>
  <div class="h-100 d-flex flex-column">
    <div class="row flex-grow-1">
      <div class="col-sm-12 col-md-7 col-lg-7 col-xl-9 height360">
        <BQdtCard
          name="turnoverTable"
          type="table"
          with-fullscreen
        />
      </div>
      <div class="col-sm-12 col-md-5 col-lg-5 col-xl-3">
        <BQdtCard
          name="turnoverCountry"
          with-fullscreen
        />
      </div>
    </div>

    <div class="row flex-grow-1">
      <div class="col-sm-12 col-md-5 col-lg-4 col-xl-4">
        <BQdtCard
          name="turnoverIm"
          with-fullscreen
        />
      </div>
      <div class="col-sm-12 col-md-5 col-lg-6 col-xl-6">
        <BQdtCard
          name="turnoverDyn"
          with-fullscreen
        />
      </div>
      <div class="col-sm-12 col-md-2 col-lg-2 col-xl-2">
        <BQdtCard
          name="turnoverBP"
          with-fullscreen
        />
      </div>
    </div>
  </div>
</template>

<script>
import { BQdtCard } from '@/components/blocks'

export default {
  name: 'Dimensions',

  components: { BQdtCard },
}
</script>
