import Ratings from './Ratings'
import RatingsHeader from './RatingsHeader'
import RatingsHelp from './RatingsHelp'
import RatingsTopMenu from './RatingsTopMenu'

export default {
  default: Ratings,
  header: RatingsHeader,
  help: RatingsHelp,
  topMenu: RatingsTopMenu,
}
