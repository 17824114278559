<template>
  <div class="h-100 d-flex flex-column">
    <div class="row h-100">
      <div class="col-sm-12 col-md-7 col-lg-7 col-xl-9">
        <BQdtCard
          name="analyticsTable"
          type="table"
          with-fullscreen
        />
      </div>
      <div class="col-sm-12 col-md-5 col-lg-5 col-xl-3">
        <BQdtCard
          name="analyticsCountry"
          with-fullscreen
        />
      </div>
    </div>
    <div class="row h-100">
      <div class="col-sm-12 col-md-5 col-lg-4 col-xl-4">
        <BQdtCard
          name="analyticsPC"
          with-fullscreen
        />
      </div>
      <div class="col-sm-12 col-md-7 col-lg-8 col-xl-8">
        <BQdtCard
          name="analyticsDyn"
          with-fullscreen
        />
      </div>
    </div>
  </div>
</template>

<script>
import { BQdtCard } from '@/components/blocks'

export default {
  name: 'CustomsValue',

  components: { BQdtCard },
}
</script>
