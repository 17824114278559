<template>
  <header class="layout-header">
    <slot />
    <div class="layout-header-extra">
      <slot name="extra" />
    </div>
  </header>
</template>

<script>
export default {
  name: 'UiLayoutHeader',
}
</script>

<style scoped lang="sass">
@import "./style"
</style>
