<template>
  <div class="row flex-grow-1">
    <div class="col-sm-12 col-md-7 col-lg-7 col-xl-7">
      <div class="row h-100">
        <div class="col-12 flex-grow-1">
          <BQdtCard
            name="balanceTable"
            type="table"
            with-fullscreen
          />
        </div>
        <div class="col-12 flex-grow-1">
          <BQdtCard
            name="balanceMap"
            with-fullscreen
          />
        </div>
      </div>
    </div>
    <div class="col-sm-12 col-md-5 col-lg-5 col-xl-5">
      <BQdtCard
        name="balanceCountry"
        with-fullscreen
      />
    </div>
  </div>
</template>

<script>
import { BQdtCard } from '@/components/blocks'

export default {
  name: 'Balance',

  components: { BQdtCard },
}
</script>
