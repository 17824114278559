import QdtComponents from 'qdt-components'

import { config } from '@/config'

const options = {
  config: {
    host: config.QLIK_HOST,
    secure: config.QLIK_SECURE,
    port: config.QLIK_PORT,
    prefix: config.QLIK_PREFIX,
  },
  connections: {
    vizApi: true,
    engineApi: false,
  },
}

const generateQdt = appId => new QdtComponents(
  { ...options.config, appId },
  options.connections,
)
export const qdtComponents = { ...QdtComponents }

export const qdtTrade = generateQdt(config.QLIK_APP_ID_TRADE)
export const qdtComplaints = generateQdt(config.QLIK_APP_ID_COMPLAINTS)

const handleSuccess = app => {
  QdtComponents.globals.qlik.on('error', e => {
    if (e.method === 'OnSessionClosed' || e.method === 'OnSessionTimedOut') {
      app.close()
      window.console.log('тут могла быть модалка ошибки')
    }
    window.console.log('e.method', e.method)
  })
}

qdtTrade.qAppPromise
  .then(handleSuccess)

qdtComplaints.qAppPromise
  .then(handleSuccess)
