<template>
  <a
    href="/"
    class="logo"
  >
    <span class="logo-icon" />
    <span class="logo-text">
      {{ $t('bi.header.company') }}
    </span>
  </a>
</template>

<script>
export default {
  name: 'HeaderLogo',
}
</script>

<style scoped lang="sass">
@import "style"
</style>
