<template>
  <div class="h-100 d-flex flex-column">
    <div class="row">
      <div class="col-sm-10 col-md-4 col-lg-3 col-xl-3">
        <BQdtCard
          name="turnoverhsTType"
          type="top-menu-item"
        />
      </div>
      <div class="col-sm-10 col-md-8 col-lg-9 col-xl-9">
        <BQdtCard
          name="turnoverhsTDim"
          type="top-menu-item"
        />
      </div>
    </div>

    <div class="col-12">
      <BQdtCard
        name="turnoverhsTable"
        type="table"
        with-fullscreen
      />
    </div>
  </div>
</template>

<script>
import { BQdtCard } from '@/components/blocks'

export default {
  name: 'ValueOfGoodsHSCode',

  components: { BQdtCard },
}
</script>
