export const SELECT_MENU_POSITIONS = {
  TOP_LEFT: 'top-left',
  TOP_CENTER: 'top-center',
  TOP_RIGHT: 'top-right',
  BOTTOM_LEFT: 'bottom-left',
  BOTTOM_CENTER: 'bottom-center',
  BOTTOM_RIGHT: 'bottom-right',
}

export const SELECT_MENU_CONTENT_POSITIONS = {
  LEFT: 'left',
  CENTER: 'center',
  RIGHT: 'right',
}
