import Comparison from './Comparison'
import ComparisonHeader from './ComparisonHeader'
import ComparisonHelp from './ComparisonHelp'
import ComparisonTopMenu from './ComparisonTopMenu'

export default {
  default: Comparison,
  header: ComparisonHeader,
  help: ComparisonHelp,
  topMenu: ComparisonTopMenu,
}
