<template>
  <UISelect
    multiple
    :value="formValues.sections"
    :error="errors.sections"
    :options="optionsData"
    :placeholder="$t('bi.field.placeholder.choose')"
    @change="handleChange"
  />
</template>

<script>
import { mapGetters } from 'vuex'

import { UISelect } from '@/components/ui'
import { updateOpenDataValuesMixin } from '@/mixins'

export default {
  name: 'SelectSections',

  components: { UISelect },

  mixins: [updateOpenDataValuesMixin],

  computed: {
    ...mapGetters('openData', [
      'options',
    ]),
    optionsData() {
      const { lang } = this.$route.params

      return this.options?.groupByParameters?.map(option => ({
        label: option[`name${ lang.toUpperCase()}`],
        value: option.value,
      }))
    },
  },

  methods: {
    handleChange(value) {
      this.setFormValue({
        name: 'sections',
        value,
      })
    },
  },
}
</script>
