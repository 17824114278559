<template>
  <div class="row no-gutters">
    <div class="col-sm-12 col-md-5 col-lg-5 ">
      <BQdtCard
        name="complaintsHomeLFLType"
        type="header-item"
      />
    </div>
    <div class="col-sm-12 col-md-5 col-lg-5">
      <BQdtCard
        name="complaintsHomeMain"
        type="header-item"
      />
    </div>
  </div>
</template>

<script>
import { BQdtCard } from '@/components/blocks'

export default {
  name: 'HomeHeader',

  components: { BQdtCard },
}
</script>
