<template>
  <component
    :is="templateView"
    page="trade"
  >
    <template #header>
      <RouterView name="header" />
    </template>

    <template #help>
      <RouterView name="help" />
    </template>

    <template #top-menu>
      <RouterView name="topMenu" />
    </template>

    <RouterView />
  </component>
</template>

<script>

import {
  BViewTemplate,
  BViewTemplateTradeComparison,
} from '@/components/blocks'

export default {
  name: 'Wrapper',

  components: {
    BViewTemplate,
    BViewTemplateTradeComparison,
  },

  computed: {
    templateView() {
      return this.$route.name === 'tradeComparison'
        ? BViewTemplateTradeComparison
        : BViewTemplate
    },
  },
}
</script>
