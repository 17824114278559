<template>
  <footer class="layout-footer">
    <slot />

    <div class="layout-footer-extra">
      <slot name="extra" />
    </div>
  </footer>
</template>

<script>
export default {
  name: 'UiLayoutFooter',
}
</script>

<style scoped lang="sass">
@import "./style"
</style>
