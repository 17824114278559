export const OPEN_DATA_CURRENCY_OPTIONS = [
  { label: 'USD', value: 'USD' },
  { label: 'UAH', value: 'UAH' },
]

export const OPEN_DATA_FORMAT_OPTIONS = [
  { label: 'XLSX', value: 'XLSX' },
  { label: 'CSV', value: 'CSV' },
]

export const OPEN_DATA_LEVEL_OPTIONS = [
  { label: 'Рік', value: 'year' },
  { label: 'Квартал', value: 'quarter' },
  { label: 'Місяць', value: 'month' },
]

export const OPEN_DATA_MIN_DATE_OF_EXPORT = new Date('01.01.2017')
