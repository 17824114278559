import Wrapper from './Wrapper'
import customsValue from './customsValue'
import balance from './balance'
import comparison from './comparison'
import home from './home'
import importExport from './importExport'
import payments from './payments'
import ratings from './ratings'
import dimensions from './dimensions'
import valueOfGoodsHSCode from './valueOfGoodsHSCode'

export default {
  Wrapper,
  customsValue,
  balance,
  comparison,
  home,
  importExport,
  payments,
  ratings,
  dimensions,
  valueOfGoodsHSCode,
}
