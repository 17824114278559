<template>
  <form class="d-flex flex-column h-100">
    <header class="header">
      <img
        src="@/assets/images/open-data-form-header.svg"
        alt=""
      >
      <div class="ml-5">
        <h3 class="title">
          {{ $t('bi.open-data.select-options') }}
        </h3>
        <div class="description">
          {{ $t('bi.open-data.selection-generation-may-take-some-time') }}
        </div>
      </div>
    </header>

    <div class="row">
      <div class="col-sm-6 mb-5">
        <div class="input-wrapper">
          <label class="label">{{ $t('bi.field.start-date') }}</label>
          <StartPeriod />
        </div>
      </div>

      <div class="col-sm-6 mb-5">
        <div class="input-wrapper">
          <label class="label">{{ $t('bi.field.end-date') }}</label>
          <EndPeriod />
        </div>
      </div>

      <div class="col-sm-6 mb-5">
        <div class="input-wrapper">
          <label class="label">{{ $t('bi.field.dimensions') }}</label>
          <SelectSections />
        </div>
      </div>

      <div class="col-sm-6 mb-5">
        <div class="input-wrapper">
          <label class="label">{{ $t('bi.field.kpi') }}</label>
          <SelectRates />
        </div>
      </div>

      <div class="col-sm-6 mb-5">
        <div class="input-wrapper">
          <label class="label">{{ $t('bi.field.data-grouping') }}</label>
          <RadioTimeInterval />
        </div>
      </div>

      <div class="col-sm-6 mb-5">
        <div class="input-wrapper">
          <label class="label">{{ $t('bi.field.currency') }}</label>
          <RadioCurrency />
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { mapActions } from 'vuex'

import {
  StartPeriod,
  EndPeriod,
  RadioTimeInterval,
  RadioCurrency,
  SelectRates,
  SelectSections,
} from './components'

export default {
  name: 'OpenDataContentForm',

  components: {
    RadioCurrency,
    RadioTimeInterval,
    EndPeriod,
    StartPeriod,
    SelectRates,
    SelectSections,
  },

  mounted() {
    this.loadOptions()
  },

  methods: {
    ...mapActions('openData', [
      'loadOptions',
    ]),
  },

}
</script>

<style scoped lang="sass">
@import "./style"
</style>
