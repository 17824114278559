<template>
  <label class="checkbox-wrapper">
    <span
      class="checkbox"
      :class="classes"
    >
      <input
        :checked="checked"
        type="checkbox"
        class="checkbox-input"
        @change="handleChange"
      >

      <transition name="fade">
        <UIIcon
          v-if="checked"
          type="checkmark"
        />
      </transition>
    </span>

    <span>
      <slot />
    </span>
  </label>
</template>

<script>
import UIIcon from '@/components/ui/UIIcon/UIIcon'

export default {
  name: 'UICheckbox',

  components: { UIIcon },

  props: {
    checked: {
      type: Boolean,
    },
  },

  computed: {
    classes() {
      return {
        'checkbox-checked': this.checked,
      }
    },
  },

  methods: {
    handleChange(e) {
      const { checked } = e.target
      this.$emit('input', checked)
      this.$emit('change', checked)
      this.$emit('click', checked)
    },
  },
}
</script>

<style scoped lang="sass">
@import "./style"
</style>
