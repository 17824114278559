<template>
  <Multiselect
    :value="value"
    :multiple="multiple"
    :options="options"
    :searchable="false"
    :close-on-select="!multiple"
    :show-labels="false"
    :placeholder="placeholder"
    track-by="value"
    label="label"
    class="select"
    :class="classes"
    @input="handleChange"
  >
    <template #caret>
      <UIIcon
        class="multiselect__select"
        type="arrow-down-medium"
      />
    </template>

    <template
      slot="singleLabel"
      slot-scope="{option}"
    >
      {{ getOptionLabel(option) }}
    </template>

    <template
      slot="selection"
      slot-scope="{ values, isOpen }"
    >
      <span
        v-if="values.length && !isOpen"
        class="multiselect__single"
        :title="getValues(values)"
      >
        {{ getValues(values) }}
      </span>
    </template>

    <template
      slot="tag"
      slot-scope="{option}"
    >
      {{ getOptionLabel(option) }},
    </template>

    <template
      #beforeList
    >
      <div class="select-actions">
        <UIButton @click="selectAll">
          {{ $t('bi.button.select-all') }}
        </UIButton>
        <UIButton @click="deselect">
          {{ $t('bi.button.deselect') }}
        </UIButton>
        <UIButton
          icon="arrow-double-left-right"
          @click="invertSelect"
        />
      </div>
    </template>

    <template
      slot="option"
      slot-scope="{option}"
    >
      <UICheckbox
        v-if="multiple"
        class="option-checkbox"
        :checked="checkActiveOption(option)"
        @click="handleClickCheckbox(option)"
      />
      <span
        :title="getOptionLabel(option)"
        class="option-label"
      >
        {{ getOptionLabel(option) }}
      </span>
    </template>
  </Multiselect>
</template>

<script>
import Multiselect from 'vue-multiselect'

import UIIcon from '@/components/ui/UIIcon/UIIcon'
import UICheckbox from '@/components/ui/UICheckbox/UICheckbox'
import helpers from '@/helpers'
import UIButton from '@/components/ui/UIButton/UIButton'

export default {
  name: 'UISelect',

  components: {
    UIButton,
    UICheckbox,
    UIIcon,
    Multiselect,
  },

  props: {
    value: {
      type: [Object, Array, String, Number],
      default: null,
    },
    multiple: {
      type: Boolean,
    },
    placeholder: {
      type: String,
      default: 'Select',
    },
    options: {
      type: Array,
      default: () => [],
      validate: helpers.validate.select.options,
    },
    error: {
      type: Boolean,
    },
  },

  computed: {
    classes() {
      return {
        error: this.error,
      }
    },
  },

  watch: {
    options(value) {
      if (!this.value.length) return

      const newValues = value.filter(item => {
        return this.value.some(val => val.value === item.value)
      })
      this.handleChange(newValues)
    },
  },

  methods: {
    getOptionLabel(option) {
      return option?.label ?? option
    },
    getValues(values) {
      return values.map(value => value?.label ?? value)
        .join(', ')
    },
    handleChange(value) {
      this.$emit('input', value)
      this.$emit('change', value)
    },
    handleClickCheckbox(option) {
      const val = this.value
      const hasOption = val.some(item => item.value === option.value)

      this.handleChange(
        hasOption
          ? val.filter(item => item.value !== option.value)
          : [...val, option],
      )
    },
    checkActiveOption(option) {
      return this.value?.some(value => value.value === option.value)
    },
    selectAll() {
      this.handleChange(this.options)
    },
    deselect() {
      this.handleChange([])
    },
    invertSelect() {
      this.handleChange(this.options.filter(option => {
        return !this.value.some(item => item.value === option.value)
      }))
    },
  },

}
</script>

<style scoped lang="sass">
@import "./style"
</style>
