const config = {
  CUSTOM_BACK_API_ROOT: 'https://api-cback-prod.customs.gov.ua/',
  API_ROOT: 'https://open-api.customs.gov.ua/',
  DATE_FORMAT_ONLY_MONTH: 'MMMM YYYY',
  DATE_SHORT_FORMAT: 'DD.MM.YYYY',
  AVAILABLE_LANGUAGES: ['uk', 'en'],
  FALLBACK_LANGUAGE: 'uk',
  FEEDBACK_EMAIL: 'service-desk@customs.gov.ua',
  GOOGLE_ANALYTICS_ID: 'UA-152371991-2',
  QLIK_HOST: 'bi-back.customs.gov.ua',
  QLIK_SECURE: true,
  QLIK_PORT: 443,
  QLIK_PREFIX: 'prod',
  QLIK_APP_ID_TRADE: '03e5c31b-0fae-4d38-8092-07c572b526a5',
  QLIK_APP_ID_COMPLAINTS: '2545de3b-b56a-4d16-a947-bd43e2c6beb6',
  URL_PARTNER_USAID: 'http://cepukraine.org/',
  URL_PARTNER_EU4PFM: 'https://eu4pfm.com.ua/',
  URL_PARTNER_QLIK: 'https://www.qlik.com/',
  URL_API_DOC: 'https://open-api.customs.gov.ua/api/doc',
  URL_DATA_EXPORT_TRADE_FULL_UK:
    'https://data.gov.ua/dataset/scsu-bi-trade-src',
  URL_DATA_EXPORT_TRADE_FULL_EN:
    'https://data.gov.ua/dataset/scsu-bi-trade-src-en',
}

export { config }
