<template>
  <UIDatePicker
    v-model="formValues.startPeriod"
    :error="errors.startPeriod"
    :placeholder="$t('bi.field.placeholder.choose-period')"
    :disabled-date="disabledDate"
    only-month
    @change="handleChange"
  />
</template>

<script>
import { mapGetters } from 'vuex'
import { UIDatePicker } from '@/components/ui'
import helpers from '@/helpers'
import { updateOpenDataValuesMixin } from '@/mixins'
import { OPEN_DATA_MIN_DATE_OF_EXPORT } from '@/constants'

export default {
  name: 'StartPeriod',

  components: { UIDatePicker },

  mixins: [updateOpenDataValuesMixin],

  computed: {
    ...mapGetters('openData', [
      'lastModifiedDate',
    ]),
    disabledDate() {
      return value => {
        const { endPeriod } = this.formValues
        const {
          disabledPast,
          disabledFeature,
        } = helpers.date

        if (!endPeriod) {
          return disabledPast(OPEN_DATA_MIN_DATE_OF_EXPORT)(value)
            || disabledFeature(new Date(this.lastModifiedDate))(value)
        }

        return disabledPast(OPEN_DATA_MIN_DATE_OF_EXPORT)(value)
          || disabledFeature(endPeriod)(value)
          || disabledFeature(new Date(this.lastModifiedDate))(value)
      }
    },
  },

  methods: {
    handleChange(value) {
      this.setFormValue({
        name: 'startPeriod',
        value,
      })
    },
  },

}
</script>
