<template>
  <BQdtCard :name="name" />
</template>

<script>
import { BQdtCard } from '@/components/blocks/qdt/BQdtCard'
import helpers from '@/helpers'

const NAMES_BY_PAGE_TYPE = {
  trade: 'globalUpdatedDate',
  complaints: 'globalUpdatedDateComplaints',
}

export default {
  name: 'QdtGlobalUpdatedDate',

  components: { BQdtCard },

  computed: {
    name() {
      const pageType = helpers.page.getType(this.$route)
      return NAMES_BY_PAGE_TYPE[pageType]
    },
  },
}
</script>
