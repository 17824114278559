import axios from 'axios'

import { config } from '@/config'

const apiCustomBack = axios.create({
  baseURL: `${config.CUSTOM_BACK_API_ROOT}api`,
})

const apiCustomBackLocalization = axios.create({
  baseURL: `${config.CUSTOM_BACK_API_ROOT}localization`,
})

const apiExportBiData = axios.create({
  baseURL: `${config.API_ROOT}api`,
})

export {
  apiCustomBack,
  apiCustomBackLocalization,
  apiExportBiData,
}
