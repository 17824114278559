import { ROUTE_NAMES } from '@/constants/routes'

export const SIDEBAR_MENU = [
  {
    id: 'trade',
    icon: 'reset',
    i18nCode: 'bi.sidebar.menu-items.turnover',
    routerName: ROUTE_NAMES.TRADE.HOME,
    subMenu: [
      {
        id: 'payments',
        i18nCode: 'bi.sidebar.menu-items.payments',
        routerName: ROUTE_NAMES.TRADE.PAYMENTS,
      },
      {
        id: 'import-export',
        i18nCode: 'bi.sidebar.menu-items.import-export',
        routerName: ROUTE_NAMES.TRADE.IMPORT_EXPORT,
      },
      {
        id: 'balance',
        i18nCode: 'bi.sidebar.menu-items.balance',
        routerName: ROUTE_NAMES.TRADE.BALANCE,
      },
      {
        id: 'turnover',
        i18nCode: 'bi.sidebar.menu-items.dimensions',
        routerName: ROUTE_NAMES.TRADE.DIMENSIONS,
      },
      {
        id: 'ratings',
        i18nCode: 'bi.sidebar.menu-items.ratings',
        routerName: ROUTE_NAMES.TRADE.RATINGS,
      },
      {
        id: 'comparison',
        i18nCode: 'bi.sidebar.menu-items.comparison',
        routerName: ROUTE_NAMES.TRADE.COMPARISON,
      },
      {
        id: 'value-of-goods-hs-code',
        i18nCode: 'bi.sidebar.menu-items.value-of-goods-hs-code',
        routerName: ROUTE_NAMES.TRADE.VALUE_OF_GOODS_HS_CODE,
      },
      {
        id: 'customs-value',
        i18nCode: 'bi.sidebar.menu-items.customs-value',
        routerName: ROUTE_NAMES.TRADE.CUSTOMS_VALUE,
      },
    ],
  },
  {
    id: 'complaints',
    icon: 'user',
    i18nCode: 'bi.sidebar.menu-items.client-centricity',
    routerName: ROUTE_NAMES.COMPLAINTS.HOME,
    subMenu: [
      {
        id: 'analytics',
        i18nCode: 'bi.sidebar.menu-items.analytic',
        routerName: ROUTE_NAMES.COMPLAINTS.ANALYTICS,
      },
      {
        id: 'ratings',
        i18nCode: 'bi.sidebar.menu-items.ratings',
        routerName: ROUTE_NAMES.COMPLAINTS.RATINGS,
      },
    ],
  },
  {
    id: 'open-data',
    icon: 'download',
    i18nCode: 'bi.sidebar.menu-items.open-data',
    routerName: ROUTE_NAMES.OPEN_DATA,
  },
]
