<template>
  <UIModal
    name="partners"
    :icon-url="iconUrl"
    title-i18n-code="bi.partners.title"
    description-i18n-code="bi.partners.description"
  >
    <div class="partners">
      <UIButton
        :href="config.URL_PARTNER_USAID"
        target="_blank"
        class="partners-button"
      >
        <img
          src="@/assets/images/partners/usaid.svg"
          alt=""
        >
      </UIButton>
      <UIButton
        :href="config.URL_PARTNER_EU4PFM"
        target="_blank"
        class="partners-button"
      >
        <img
          src="@/assets/images/partners/eu4pfm.svg"
          alt=""
        >
      </UIButton>
      <UIButton
        :href="config.URL_PARTNER_QLIK"
        target="_blank"
        class="partners-button"
      >
        <img
          src="@/assets/images/partners/qlik.svg"
          alt=""
        >
      </UIButton>
    </div>
  </UIModal>
</template>

<script>
import { UIModal, UIButton } from '@/components/ui'
import iconHeartUrl from '@/assets/images/big-icon-heart.svg'
import { config } from '@/config'

export default {
  name: 'BModalPartners',

  components: {
    UIButton,
    UIModal,
  },

  computed: {
    iconUrl() {
      return iconHeartUrl
    },
    config() {
      return config
    },
  },
}
</script>

<style scoped lang="sass">
@import "./style"
</style>
