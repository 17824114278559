<template>
  <component
    :is="tag"
    :to="route"
    :href="href"
    :type="htmlType"
    class="button"
    :class="classes"
    @click="handleClick"
  >
    <UIIcon
      v-if="icon"
      :type="icon"
    />
    <slot />
  </component>
</template>

<script>
import helpers from '@/helpers'
import UIIcon from '@/components/ui/UIIcon/UIIcon'

export default {
  name: 'UIButton',

  components: { UIIcon },

  props: {
    type: {
      type: String,
      default: null,
      validator: helpers.validate.buttonType,
    },
    href: {
      type: String,
      default: null,
    },
    routeName: {
      type: String,
      default: null,
      validator: helpers.validate.routeName,
    },
    icon: {
      type: String,
      default: null,
      validator: helpers.validate.iconType,
    },
    size: {
      type: String,
      default: null,
      validator: helpers.validate.buttonSize,
    },
    htmlType: {
      type: String,
      default: 'button',
    },
  },

  data() {
    return {
      tag: 'button',
    }
  },

  computed: {
    classes() {
      const {
        type, size, $slots, icon,
      } = this
      return {
        [`button-${type}`]: type,
        [`button-${size}`]: size,
        'button-only-icon': !$slots.default && icon,
      }
    },
    route() {
      return this.routeName && { name: this.routeName }
    },
  },

  watch: {
    href(value) {
      this.tag = value ? 'a' : 'button'
    },
  },

  mounted() {
    this.checkTag()
  },

  methods: {
    handleClick(e) {
      this.$emit('click', e)
    },
    checkTag() {
      if (this.href) this.tag = 'a'
      if (this.routeName) this.tag = 'RouterLink'
    },
  },
}
</script>

<style scoped lang="sass">
@import "./style"
</style>
