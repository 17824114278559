<template>
  <ul
    class="sidebar-menu"
    :class="classes"
  >
    <li
      v-for="item in menuItems"
      :key="item.id"
      class="menu-item"
    >
      <SidebarMenuItem
        :router-name="item.routerName"
        :icon="item.icon"
        :i18n-code="item.i18nCode"
        :has-sub-menu="Boolean(item.subMenu)"
        :has-sub-menu-collapsed="checkSubMenuCollapsed(item.id)"
        @click="handleClickMenuItem"
        @toggle-sidebar="handleToggleSidebar(item.id)"
      />

      <ul
        v-if="item.subMenu"
        class="sub-menu"
        :class="{
          'sub-menu-collapsed': checkSubMenuCollapsed(item.id),
        }"
      >
        <li
          v-for="subItem in item.subMenu"
          :key="subItem.id"
          class="menu-item"
        >
          <SidebarMenuItem
            :router-name="subItem.routerName"
            :icon="subItem.icon"
            :i18n-code="subItem.i18nCode"
            @click="handleClickMenuItem"
          />
        </li>
      </ul>
    </li>
    <li class="d-sm-none">
      <CButtonFeedback />
    </li>
  </ul>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

import { CButtonFeedback } from '@/components/containers'
import { BREAKPOINTS, SIDEBAR_MENU } from '@/constants'

import { SidebarMenuItem } from './components'

export default {
  name: 'BSidebarMenu',

  components: { CButtonFeedback, SidebarMenuItem },

  computed: {
    ...mapGetters('common', [
      'isCollapsedSidebar',
      'openedSideBars',
    ]),
    classes() {
      return {
        'sidebar-menu-collapsed': this.isCollapsedSidebar,
      }
    },
    menuItems() {
      return SIDEBAR_MENU
    },
  },

  methods: {
    ...mapMutations('common', [
      'setCollapsedSidebar',
      'setOpenedSideBars',
    ]),
    handleClickMenuItem() {
      if (window.innerWidth < BREAKPOINTS.DESKTOP) {
        this.setCollapsedSidebar(!this.isCollapsedSidebar)
      } else if (this.isCollapsedSidebar) {
        this.setOpenedSideBars([])
      }
    },
    handleToggleSidebar(id) {
      if (this.openedSideBars.includes(id)) {
        this.setOpenedSideBars(this.openedSideBars.filter(item => item !== id))
        return
      }

      if (this.isCollapsedSidebar) {
        this.setOpenedSideBars([id])
        return
      }

      this.setOpenedSideBars([...this.openedSideBars, id])
    },
    checkSubMenuCollapsed(id) {
      return Boolean(!this.openedSideBars.includes(id))
    },
  },
}
</script>

<style scoped lang="sass">
@import "./style"
</style>
