<template>
  <UiLayoutFooter>
    <div class="copyright">
      © {{ $t('bi.footer.copyright') }}
    </div>

    <template slot="extra">
      <BButtonModalPartners />
    </template>
  </UiLayoutFooter>
</template>

<script>
import { UiLayoutFooter } from '@/components/ui'
import { BButtonModalPartners } from '@/components/blocks/modals'

export default {
  name: 'BFooter',

  components: { BButtonModalPartners, UiLayoutFooter },
}
</script>

<style scoped lang="sass">
@import "./style"
</style>
