<template>
  <main class="layout-content">
    <slot />
  </main>
</template>

<script>
export default {
  name: 'UiLayoutContent',
}
</script>

<style scoped lang="sass">
@import "./style"
</style>
