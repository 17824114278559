<template>
  <div class="content-wrapper">
    <OpenDataContentForm v-if="isFormView" />

    <div v-else>
      <UIButton
        icon="close-big"
        class="button-close"
        @click="clearStatus"
      />
      <OpenDataStatusView />
    </div>

    <OpenDataContentFooter class="mt-auto" />

    <div class="hide-images">
      <img
        src="~@/assets/images/open-data-start-loading.gif"
        alt=""
      >
      <img
        src="~@/assets/images/open-data-loading.gif"
        alt=""
      >
      <img
        src="~@/assets/images/open-data-success.gif"
        alt=""
      >
      <img
        src="~@/assets/images/open-data-error.gif"
        alt=""
      >
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

import { UIButton } from '@/components/ui'
import {
  OpenDataStatusView,
  OpenDataContentFooter,
  OpenDataContentForm,
} from './components'

export default {
  name: 'BOpenDataContent',

  components: {
    UIButton,
    OpenDataContentForm,
    OpenDataStatusView,
    OpenDataContentFooter,
  },

  computed: {
    ...mapGetters('openData', [
      'status',
    ]),
    isFormView() {
      return !this.status
    },
  },

  methods: {
    ...mapMutations('openData', [
      'clearStatus',
    ]),
  },
}
</script>

<style scoped lang="sass">
@import "./style"
</style>
