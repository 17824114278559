<template>
  <UIButton :href="feedbackLink">
    {{ $t('bi.header.feedback') }}
    <UIIcon type="mail" />
  </UIButton>
</template>

<script>
import { config } from '@/config'
import { UIButton, UIIcon } from '@/components/ui'

export default {
  name: 'CButtonFeedback',

  components: { UIIcon, UIButton },

  computed: {
    feedbackLink() {
      return `mailto:${config.FEEDBACK_EMAIL}`
    },
  },
}
</script>
