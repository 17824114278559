<template>
  <DatePicker
    v-if="renderComponent"
    :class="classes"
    :value="value"
    :type="type"
    :placeholder="placeholder"
    :disabled-date="disabledDate"
    :lang="language"
    :format="format"
    popup-class="custom-calendar"
    @change="handleChange"
  >
    <UIIcon
      slot="icon-calendar"
      type="calendar"
    />
  </DatePicker>
</template>

<script>
import { mapGetters } from 'vuex'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import langUk from 'vue2-datepicker/locale/uk'
import langEn from 'vue2-datepicker/locale/en'

import UIIcon from '@/components/ui/UIIcon/UIIcon'

export default {
  name: 'UIDatePicker',

  components: {
    UIIcon,
    DatePicker,
  },

  props: {
    value: {
      type: [Date, String],
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    disabledDate: {
      type: Function,
      default: () => false,
    },
    onlyMonth: {
      type: Boolean,
    },
    error: {
      type: Boolean,
    },
  },

  data() {
    return {
      renderComponent: true,
    }
  },

  computed: {
    ...mapGetters('common', [
      'lang',
    ]),
    classes() {
      return {
        error: this.error,
      }
    },
    format() {
      if (this.onlyMonth) return 'MMMM YYYY'
      return 'DD.MM.YYYY'
    },
    type() {
      if (this.onlyMonth) return 'month'
      return 'date'
    },
    language() {
      this.forceRerender()
      return this.lang === 'uk' ? langUk : langEn
    },
  },

  methods: {
    handleChange(val) {
      this.$emit('input', val)
      this.$emit('change', val)
    },
    forceRerender() {
      this.renderComponent = false

      this.$nextTick(() => {
        this.renderComponent = true
      })
    },
  },
}
</script>

<style scoped lang="sass">
@import "./style"
</style>
