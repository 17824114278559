<template>
  <div
    class="qdt-card"
    :class="classes"
  >
    <UIButton
      v-if="withFullscreen"
      class="fullscreen-switcher"
      :icon="iconTypes"
      @click="handleClick"
    />

    <div
      v-if="displayOverlay"
      class="qdt-card-overlay"
    />

    <BQdt
      v-if="renderComponent"
      :name="name"
      :type="type"
    />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

import helpers from '@/helpers'
import { BQdt } from '@/components/blocks/qdt/BQdt'
import { UIButton } from '@/components/ui'
import { QDT_TYPES } from '@/constants'

export default {
  name: 'BQdtCard',

  components: {
    UIButton,
    BQdt,
  },

  props: {
    name: {
      type: String,
      required: true,
      validator: helpers.validate.qdtName,
    },
    type: {
      type: String,
      default: QDT_TYPES.DEFAULT,
      validator: helpers.validate.qdtType,
    },
    withFullscreen: {
      type: Boolean,
    },
  },

  data() {
    return {
      fullscreen: false,
      renderComponent: true,
    }
  },

  computed: {
    ...mapGetters('common', [
      'isCollapsedSidebar',
      'lang',
    ]),
    classes() {
      return {
        [`qdt-${this.name}`]: this.name,
        [`qdt-type-${this.type}`]: this.type && this.type !== QDT_TYPES.DEFAULT,
        fullscreen: this.fullscreen,
        'with-fullscreen': this.withFullscreen,
        'fullscreen-full-width': this.fullscreen && this.isCollapsedSidebar,
      }
    },
    iconTypes() {
      return this.fullscreen ? 'arrow-minimize' : 'arrow-expand'
    },
    displayOverlay() {
      return !this.fullscreen
        && this.type !== QDT_TYPES.FILTER_HS_CODE
        && this.type !== QDT_TYPES.FILTER_SAMPLE
    },
  },

  watch: {
    fullscreen() {
      this.$qdt.globals.resize()
    },
    name() {
      this.forceRerender()
    },
    $route(value) {
      if (this.name === 'globalFilterReferenceSample') {
        if (!this.lang) return
        if (helpers.page.getType(value) !== 'trade') return

        const title = this.lang === 'uk' ? 'Валюта' : 'Currency'
        helpers.common.toggleBlock(
          value.name === 'tradeComparison' ? 'none' : 'block',
          `.qdt-globalFilterReferenceSample .qdt-wrapper [title="${title}"]`,
          '.qv-filterpane-collapsed',
        )
      }
    },
  },

  mounted() {
    if (this.name === 'globalFilterReferenceSample') {
      if (!this.lang) return
      if (this.$route.name !== 'tradeComparison') return

      const title = this.lang === 'uk' ? 'Валюта' : 'Currency'
      helpers.common.toggleBlock(
        'none',
        `.qdt-globalFilterReferenceSample .qdt-wrapper [title="${title}"]`,
        '.qv-filterpane-collapsed',
      )
    }

    if (this.name === 'complaintsAnalyticsFilters') {
      if (!this.lang) return

      const title = this.lang === 'uk' ? 'Мова' : 'Language'
      helpers.common.toggleBlock(
        'none',
        `.qdt-complaintsAnalyticsFilters .qdt-wrapper [title="${title}"]`,
        '.qv-filterpane-collapsed',
      )
    }
  },

  methods: {
    ...mapMutations('qdt', [
      'setFullscreen',
    ]),
    handleClick() {
      this.fullscreen = !this.fullscreen
      this.setFullscreen(this.fullscreen)
    },
    forceRerender() {
      this.renderComponent = false

      this.$nextTick(() => {
        this.renderComponent = true
      })
    },
  },
}
</script>

<style scoped lang="sass">
@import "./style"
</style>
