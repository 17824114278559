import Vue from 'vue'
import VModal from 'vue-js-modal'
import VueAnalytics from 'vue-analytics'

import 'bootstrap-4-grid/css/grid.min.css'
import 'vue-multiselect/dist/vue-multiselect.min.css'

import qdt from '@/services/qdt'
import api from '@/services/api'
import i18n from '@/services/i18n'
import { config } from '@/config'

import App from './App'
import router from './router'
import store from './store'

Vue.use(qdt)
Vue.use(api)
Vue.use(VModal)

Vue.use(VueAnalytics, {
  id: config.GOOGLE_ANALYTICS_ID,
  router,
  debug: {
    sendHitTask: process.env.NODE_ENV === 'production',
  },
})

Vue.config.productionTip = false
new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
