<template>
  <div class="row flex-grow-1">
    <div class="col-sm-6 col-lg-3">
      <BQdtCard
        name="ratingsBTop"
        with-fullscreen
      />
    </div>
    <div class="col-sm-10 col-lg-6">
      <div class="row h-100">
        <div class="col-12 flex-grow-1">
          <BQdtCard
            name="ratingsBButton"
            type="button"
          />
          <BQdtCard
            name="ratingsBTable"
            type="table"
            with-fullscreen
          />
        </div>
        <div class="col-12 flex-grow-1">
          <BQdtCard
            name="ratingsWButton"
            type="button"
          />
          <BQdtCard
            name="ratingsWTable"
            type="table"
            with-fullscreen
          />
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-lg-3">
      <BQdtCard
        name="ratingsWTop"
        with-fullscreen
      />
    </div>
  </div>
</template>

<script>
import { BQdtCard } from '@/components/blocks'

export default {
  name: 'Ratings',

  components: { BQdtCard },
}
</script>
