import { mapGetters, mapMutations } from 'vuex'

export const updateOpenDataValuesMixin = {
  computed: {
    ...mapGetters('openData', [
      'formValues',
      'errors',
    ]),
  },

  methods: {
    ...mapMutations('openData', [
      'setFormValue',
    ]),
  },
}
