<template>
  <div
    class="status-view-wrapper"
    :class="classes"
  >
    <div class="image">
      <img
        v-if="status === 'loading' && !started"
        src="~@/assets/images/open-data-start-loading.gif"
        alt=""
      >
      <img
        v-if="status === 'loading' && started"
        src="~@/assets/images/open-data-loading.gif"
        alt=""
      >
      <img
        v-if="status === 'success'"
        src="~@/assets/images/open-data-success.gif"
        alt=""
      >
      <img
        v-if="status === 'error'"
        src="~@/assets/images/open-data-error.gif"
        alt=""
      >
      <img
        v-if="status === 'errorHeavyQuery'"
        src="~@/assets/images/open-data-error-heavy-query.svg"
        alt=""
        class="image-heavy-query"
      >
    </div>

    <div class="title">
      {{ $t(title) }}
    </div>

    <div
      class="description"
      v-html="$t(description)"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

const TITLES_BY_STATUS = {
  loading: 'bi.open-data.generation-selection',
  success: 'bi.open-data.selection-ready',
  error: 'bi.open-data.error',
  errorHeavyQuery: 'bi.error.heavy-query-title',
}

const DESCRIPTION_BY_STATUS = {
  loading: 'bi.open-data.selection-generation-may-take-some-time',
  success: 'bi.open-data.if-the-file-is-not-loaded-automatically',
  error: 'bi.open-data.failed-to-create-sample-data',
  errorHeavyQuery: 'bi.open-data.we-recommend-download-or-create',
}

export default {
  name: 'OpenDataStatusView',

  data() {
    return {
      started: false,
    }
  },

  computed: {
    ...mapGetters('openData', [
      'status',
    ]),
    classes() {
      return {
        [`status-view-${this.status}`]: this.status,
      }
    },
    title() {
      return TITLES_BY_STATUS[this.status]
    },
    description() {
      return DESCRIPTION_BY_STATUS[this.status]
    },
  },

  mounted() {
    setTimeout(() => {
      this.started = true
    }, 1000)
  },
}
</script>

<style scoped lang="sass">
@import "./style"
</style>
