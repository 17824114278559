import { qdtTrade, qdtComplaints, qdtComponents } from './init'

const repositories = {
  trade: qdtTrade,
  complaints: qdtComplaints,
  ...qdtComponents,
}

const qdt = {
  install: Vue => {
    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$qdt = repositories
  },
}

export default qdt
