<template>
  <BQdtCard
    name="balanceTitle"
    type="header-item"
  />
</template>

<script>
import { BQdtCard } from '@/components/blocks'

export default {
  name: 'BalanceHeader',

  components: { BQdtCard },
}
</script>
