<template>
  <div class="row no-gutters justify-content-center h-100">
    <div class="col-sm-12 col-md">
      <BQdtCard
        name="turnoverDim"
        type="top-menu-item"
      />
    </div>
    <div class="col-sm-12 col-md">
      <BQdtCard
        name="turnoverMes"
        type="top-menu-item"
      />
    </div>
  </div>
</template>

<script>
import { BQdtCard } from '@/components/blocks'

export default {
  name: 'DimensionsTopMenu',

  components: { BQdtCard },
}
</script>
