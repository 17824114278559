<template>
  <div class="wrapper">
    <div class="circles">
      <div class="circle circle-first" />
      <div class="circle circle-second" />
    </div>
    <div class="header">
      404
      <img
        class="header-icon"
        src="@/assets/images/page_404_pin.svg"
        alt=""
      >
    </div>
    <div class="description">
      <p class="description-text">
        На жаль, такої сторінки не існує.<br>
        Будь ласка, поверніться на головну сторінку
      </p>
    </div>

    <UIButton
      class="button"
      type="primary"
      :route-name="homeRouterName"
    >
      На головну
      <UIIcon type="arrow-right-big" />
    </UIButton>
  </div>
</template>

<script>
import { UIButton, UIIcon } from '@/components/ui'
import { ROUTE_NAMES } from '@/constants'

export default {
  name: 'BErrorPageTemplate',

  components: { UIIcon, UIButton },

  computed: {
    homeRouterName() {
      return ROUTE_NAMES.TRADE.HOME
    },
  },
}
</script>

<style scoped lang="sass">
@import "style"
</style>
