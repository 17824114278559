<template>
  <div>
    <BQdtCard
      v-if="qdtLangName"
      ref="language"
      class="d-none"
      :name="qdtLangName"
    />
    <div
      v-if="lang"
      class="wrapper"
    >
      <UIDropdown
        placement="bottom-right"
        position-menu-content="center"
        :options="options"
        :loading="qdtLangName && isEmptyButtons"
        @change="handleChangeLanguage"
      >
        {{ lang }}
      </UIDropdown>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'

import isEmpty from 'lodash/isEmpty'

import { config } from '@/config'
import { UIDropdown } from '@/components/ui'
import { BQdtCard } from '@/components/blocks/qdt'
import helpers from '@/helpers'

const { AVAILABLE_LANGUAGES, FALLBACK_LANGUAGE } = config

const QDT_LANG_NAMES_BY_PAGE_TYPE = {
  trade: 'globalLang',
  complaints: 'globalLangComplaints',
}

export default {
  name: 'BLanguage',

  components: { BQdtCard, UIDropdown },

  data() {
    return {
      lang: this.$route.params.lang.toUpperCase(),
      buttons: {},
    }
  },

  computed: {
    isEmptyButtons() {
      return isEmpty(this.buttons)
    },
    options() {
      return AVAILABLE_LANGUAGES.map(lang => ({
        value: lang,
        label: lang.toUpperCase(),
      }))
    },
    qdtLangName() {
      const pageType = helpers.page.getType(this.$route)
      return QDT_LANG_NAMES_BY_PAGE_TYPE[pageType]
    },
  },

  watch: {
    $route(newVal, oldVal) {
      const newPageType = helpers.page.getType(newVal)
      const oldPageType = helpers.page.getType(oldVal)

      if (newPageType !== oldPageType) {
        this.checkingLoadingLanguageButtons()
      }
    },
  },

  async created() {
    const { lang } = this.$route.params

    if (!AVAILABLE_LANGUAGES.includes(lang)) {
      await this.loadLanguage(FALLBACK_LANGUAGE)
      this.localeFallbackRedirect()
    } else {
      await this.loadLanguage(lang)
      this.$i18n.locale = lang
    }
  },

  mounted() {
    const { lang } = this.$route.params

    this.setLang(lang)
    this.checkingLoadingLanguageButtons()
  },

  methods: {
    ...mapMutations('common', [
      'showErrorMessage',
      'setLang',
    ]),
    checkingLoadingLanguageButtons() {
      if (!this.qdtLangName) return

      const checking = setInterval(() => {
        if (!this.isEmptyButtons || !this.qdtLangName) {
          clearInterval(checking)
          this.clickQdtLangButton()
        }

        this.setLanguageButtons()
      }, 1000)
    },
    setLanguageButtons() {
      const buttonsWrapper = this.$refs.language?.$el
      if (!buttonsWrapper) return
      const langButtons = buttonsWrapper.querySelectorAll('button[title]')
      const buttons = {}

      for (let i = 0; i < langButtons.length; i += 1) {
        buttons[langButtons[i].title] = langButtons[i]
      }

      this.buttons = buttons
    },
    clickQdtLangButton() {
      const { buttons, lang } = this
      const qdtLangButton = buttons[lang.toUpperCase()] || buttons.UA
      if (qdtLangButton) qdtLangButton.click()
    },
    async loadLanguage(lang) {
      await this.$api.language.load(lang)
        .then(resp => {
          const localeMessage = resp.data?.reduce((acc, item) => {
            acc[item.key] = item.value
            return acc
          }, {}) ?? {}

          this.$i18n.setLocaleMessage(lang, localeMessage)
        })
    },
    async handleChangeLanguage(lang) {
      const { locale, messages } = this.$i18n
      if (locale === lang.value) return

      if (!messages[lang.value]) {
        await this.loadLanguage(lang.value)
      }

      this.setLang(lang.value)
      this.lang = lang.label
      this.clickQdtLangButton()
      this.$i18n.locale = lang.value
      this.redirectWithNewLang(lang.value)
    },
    localeFallbackRedirect() {
      const { lang } = this.$route.params
      this.showErrorMessage(
        `Під час завантаження мови "${lang}" сталася помилка.
        Завантажена українська мова.`,
      )

      this.$root.$i18n.locale = FALLBACK_LANGUAGE
      this.lang = FALLBACK_LANGUAGE
      this.redirectWithNewLang(FALLBACK_LANGUAGE)
    },
    redirectWithNewLang(lang) {
      this.$router.push({
        name: this.$route.name,
        params: {
          ...this.$route.params,
          lang,
        },
      })
    },
  },
}
</script>

<style scoped lang="sass">
@import "style"
</style>
