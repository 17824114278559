<template>
  <UIRadioGroup
    :value="formValues.currency"
    :options="options"
    @change="handleChange"
  />
</template>

<script>
import { UIRadioGroup } from '@/components/ui'
import { OPEN_DATA_CURRENCY_OPTIONS } from '@/constants'
import { updateOpenDataValuesMixin } from '@/mixins'

export default {
  name: 'RadioCurrency',

  components: { UIRadioGroup },

  mixins: [updateOpenDataValuesMixin],

  computed: {
    options() {
      return OPEN_DATA_CURRENCY_OPTIONS
    },
  },

  methods: {
    handleChange(value) {
      this.setFormValue({
        name: 'currency',
        value,
      })
    },
  },
}
</script>
