<template>
  <div
    class="footer-wrapper"
    :class="classes"
  >
    <UIButton
      v-if="isFormView"
      icon="plus"
      type="primary"
      :href="hrefDownload"
      :disabled="disabled"
    >
      {{ $t('bi.open-data.create-selection') }}
    </UIButton>

    <UIButton
      v-if="isFormView"
      @click="resetFormValues"
    >
      {{ $t('bi.open-data.reset-filter') }}
      <UIIcon type="reset" />
    </UIButton>

    <div v-if="isLoading || isSuccess">
      <UIButton
        icon="download"
        type="primary"
        :disabled="!isSuccess"
        @click="downloadReport"
      >
        {{ $t('bi.open-data.download') }}
      </UIButton>
    </div>

    <UIButton
      v-if="isErrorHeavyQuery"
      type="primary"
      :href="link"
      target="_blank"
      @click="handleClick"
    >
      {{ $t('bi.open-data.download-full-base') }}
      <UIIcon type="download" />
    </UIButton>

    <UIButton
      v-if="!isFormView"
      @click="createNew"
    >
      {{ $t('bi.open-data.create-request') }}
      <UIIcon type="arrow-right-big" />
    </UIButton>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import qs from 'qs'
import { isEmpty } from 'lodash'

import { UIButton, UIIcon } from '@/components/ui'
import { config } from '@/config'
import helpers from '@/helpers'

export default {
  name: 'OpenDataContentFooter',

  components: {
    UIIcon,
    UIButton,
  },

  data() {
    return {
      hrefDownload: null,
      disabled: true,
    }
  },

  computed: {
    ...mapGetters('openData', [
      'status',
      'formValues',
    ]),
    ...mapGetters('common', [
      'lang',
    ]),
    classes() {
      return {
        'justify-content-center': this.isError,
      }
    },
    link() {
      return this.lang === 'uk'
        ? config.URL_DATA_EXPORT_TRADE_FULL_UK
        : config.URL_DATA_EXPORT_TRADE_FULL_EN
    },
    isFormView() {
      return !this.status
    },
    isLoading() {
      return this.status === 'loading'
    },
    isSuccess() {
      return this.status === 'success'
    },
    isError() {
      return this.status === 'error'
    },
    isErrorHeavyQuery() {
      return this.status === 'errorHeavyQuery'
    },
  },

  watch: {
    formValues: {
      deep: true,
      handler(value) {
        const errors = Object.entries(value)
          .reduce((acc, [key, val]) => {
            const isValid = !helpers.validate.isEmptyValue(val)
            if (!isValid) acc[key] = true
            return acc
          }, {})

        if (!isEmpty(errors)) {
          this.hrefDownload = null
          this.disabled = true
          return null
        }

        const {
          startPeriod,
          endPeriod,
          sections,
          timeInterval,
          rates,
          currency,
        } = value
        const { parseOpenDataDate } = helpers.date
        const start = parseOpenDataDate(startPeriod)
        const end = parseOpenDataDate(endPeriod)
        const groupBy = sections?.map(item => item.value).join(',')
        const indicators = rates?.map(item => item.value).join(',')
        const lang = this.lang.toUpperCase()

        const params = {
          dateRange: `${start}-${end}`,
          timeInterval,
          groupBy,
          indicators,
          currency,
        }

        const apiLink = `${config.API_ROOT}api/external_trade_data/${lang}`
        this.hrefDownload = `${apiLink}?${qs.stringify(params)}`
        this.disabled = false
        return null
      },
    },
  },

  methods: {
    ...mapMutations('openData', [
      'setStatus',
      'clearStatus',
    ]),
    ...mapActions('openData', [
      'createSample',
      'downloadReport',
      'resetFormValues',
    ]),
    ...mapActions('common', [
      'showErrorMessage',
    ]),
    createNew() {
      this.resetFormValues()
      this.clearStatus()
    },
    handleClick() {
      this.$ga.event('EXPORT', 'DOWNLOAD_FULL_DUMP', null, null)
    },
  },
}
</script>

<style scoped lang="sass">
@import "./style"
</style>
