<template>
  <div class="row no-gutters h-100">
    <div class="col-sm-12 col-md-5 col-lg-3 no-flow">
      <BQdtCard
        name="globalComparisons"
        type="header-item"
      />
    </div>
    <div class="col-sm-9 col-md-7 col-lg-6">
      <BQdtCard
        name="globalVSYears"
        type="header-item"
      />
    </div>
  </div>
</template>

<script>
import { BQdtCard } from '@/components/blocks'

export default {
  name: 'HomeHeader',

  components: { BQdtCard },
}
</script>
