import Wrapper from './Wrapper'
import analytics from './analytics'
import home from './home'
import ratings from './ratings'

export default {
  Wrapper,
  analytics,
  home,
  ratings,
}
