<template>
  <div class="h-100 d-flex flex-column">
    <div class="row flex-grow-1">
      <div class="col-sm-12 col-md-4 col-lg">
        <BQdtCard name="complaintsHomeKPI1" />
      </div>
      <div class="col-sm-12 col-md-4 col-lg">
        <BQdtCard name="complaintsHomeKPI2" />
      </div>
      <div class="col-sm-12 col-md-4 col-lg">
        <BQdtCard name="complaintsHomeKPI3" />
      </div>
      <div class="col-sm-12 col-md-6 col-lg">
        <BQdtCard name="complaintsHomeKPI4" />
      </div>
      <div class="col-sm-12 col-md-6 col-lg-3">
        <BQdtCard name="complaintsHomeKPI5" />
      </div>
    </div>

    <div class="row flex-grow-2">
      <div class="col-sm-12 col-md-5">
        <BQdtCard
          name="complaintsHomeMap"
          with-fullscreen
        />
      </div>
      <div class="col-sm-12 col-md-7">
        <BQdtCard
          name="complaintsHomeHeatMap"
          with-fullscreen
        />
      </div>
    </div>
  </div>
</template>

<script>
import { BQdtCard } from '@/components/blocks'

export default {
  name: 'Home',

  components: { BQdtCard },
}
</script>
