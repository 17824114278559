<template>
  <div
    v-click-outside="hideMenu"
    class="select"
  >
    <div
      class="select-trigger"
      :class="triggerClasses"
      @click="toggleMenu"
    >
      <slot>
        <span class="placeholder">{{ placeholder }}</span>
      </slot>

      <UIIcon
        v-if="iconType"
        :type="iconType"
      />
    </div>

    <transition name="fade">
      <div
        v-if="isOpened"
        class="select-menu"
        :class="selectMenuClasses"
      >
        <ul
          v-if="options"
          class="menu"
          :class="menuClasses"
        >
          <li
            v-for="option in options"
            :key="option.value"
            class="menu-item"
            @click="handleClickMenuItem(option)"
          >
            {{ option.label }}
          </li>
        </ul>
        <div
          v-else
          class="menu-empty"
        >
          No Data
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside'

import helpers from '@/helpers'
import { UIIcon } from '@/components/ui/UIIcon'

export default {
  name: 'UIDropdown',

  components: { UIIcon },

  directives: {
    ClickOutside,
  },

  props: {
    options: {
      type: Array,
      default: null,
      validator: helpers.validate.select.options,
    },
    disabled: {
      type: Boolean,
    },
    loading: {
      type: Boolean,
    },
    placement: {
      type: String,
      default: 'bottom-left',
      validator: helpers.validate.select.menuPosition,
    },
    positionMenuContent: {
      type: String,
      default: 'left',
      validator: helpers.validate.select.menuContentPosition,
    },
    placeholder: {
      type: String,
      default: 'Select',
    },
  },

  data() {
    return {
      isOpened: false,
    }
  },

  computed: {
    triggerClasses() {
      return {
        disabled: this.disabled,
        loading: this.loading,
      }
    },
    selectMenuClasses() {
      return {
        [`pos-${this.placement}`]: this.placement,
      }
    },
    menuClasses() {
      return {
        [`content-${this.positionMenuContent}`]: this.positionMenuContent,
      }
    },
    iconType() {
      if (this.loading) return 'spinner'
      if (!this.disabled) return 'arrow-down-small-filled'
      return null
    },
  },

  methods: {
    toggleMenu() {
      if (this.disabled || this.loading) return

      this.isOpened = !this.isOpened
    },
    hideMenu() {
      this.isOpened = false
    },
    handleClickMenuItem(option) {
      this.$emit('change', option)
      this.hideMenu()
    },
  },
}
</script>

<style scoped lang="sass">
@import "./style"
</style>
