import CustomsValue from './CustomsValue'
import CustomsValueHeader from './CustomsValueHeader'
import CustomsValueHelp from './CustomsValueHelp'
import CustomsValueTopMenu from './CustomsValueTopMenu'

export default {
  default: CustomsValue,
  header: CustomsValueHeader,
  help: CustomsValueHelp,
  topMenu: CustomsValueTopMenu,
}
