<template>
  <div class="header-title">
    {{ $t(titleI18nCode) }}
  </div>
</template>

<script>
import helpers from '@/helpers'

const HEADER_TITLES_BY_PAGE_TYPE = {
  trade: 'bi.header.title-trade-indicators',
  complaints: 'bi.header.title-client-centricity-indicators',
  other: 'bi.header.open-data',
}

export default {
  name: 'HeaderTitle',

  computed: {
    titleI18nCode() {
      const pageType = helpers.page.getType(this.$route)
      return HEADER_TITLES_BY_PAGE_TYPE[pageType]
    },
  },

  watch: {
    $route() {
      this.updateDocumentTitle()
    },
  },

  mounted() {
    const checking = setInterval(() => {
      if (this.$te('bi.header.company')) {
        clearInterval(checking)
        this.updateDocumentTitle()
      }
    }, 300)
  },

  methods: {
    updateDocumentTitle() {
      const title = this.$t(this.titleI18nCode)
      const company = this.$t('bi.header.company')
      document.title = `${title} | ${company}`
    },
  },
}
</script>

<style scoped lang="sass">
@import "style"
</style>
