<template>
  <div class="qdt-wrapper">
    <p>QdtComponent</p>
    <p>Name: {{ name }}</p>
  </div>
</template>

<script>
import { QDT_TYPES, QDTS } from '@/constants'
import helpers from '@/helpers'

export default {
  name: 'BQdt',

  props: {
    name: {
      type: String,
      required: true,
      validator: helpers.validate.qdtName,
    },
    type: {
      type: String,
      default: QDT_TYPES.DEFAULT,
      validator: helpers.validate.qdtType,
    },
  },

  watch: {
    '$i18n.locale': function (val) {
      this.createQdt()
      this.setLanguage(val)
    },
  },

  beforeDestroy() {
    this.$qdt.unmountQdtComponent(this.$el)
  },

  mounted() {
    this.createQdt()
    setTimeout(() => {
      this.setLanguage('uk')
    }, 2000)
  },

  methods: {
    getQdtFunction() {
      const pageType = helpers.page.getType(this.$route)
      return pageType === 'complaints'
        ? this.$qdt.complaints
        : this.$qdt.trade
    },
    setLanguage(lang) {
      const qdtFunction = this.getQdtFunction()

      qdtFunction.qAppPromise
        .then(() => {
          this.$qdt.globals.qlik.setLanguage(lang === 'uk' ? 'ru' : lang)
        })
    },
    createQdt() {
      const qdtFunction = this.getQdtFunction()

      if (!qdtFunction) return

      const extraProps = this.type === QDT_TYPES.TABLE
        ? { exportDataTitle: this.$t('bi.qdt.download') }
        : {}

      const {
        type: qdtType,
        props: qdtProps,
      } = QDTS[this.name] || {}

      qdtFunction.render(
        qdtType,
        {
          ...qdtProps,
          ...extraProps,
        },
        this.$el,
      )
    },
  },
}
</script>

<style lang="sass">
@import "style"
</style>
