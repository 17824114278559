<template>
  <section class="view-wrapper">
    <div class="content-header-wrapper">
      <BContentHeader :page="page" />
    </div>

    <div class="header-wrapper">
      <div class="header">
        <slot name="header" />

        <div class="text-right">
          <slot name="help" />
        </div>
      </div>

      <div class="top-menu">
        <slot name="top-menu" />
      </div>
    </div>

    <div class="left-part">
      <div class="filters">
        <h3 class="filters-title">
          {{ $t('bi.comparison.filters.title.sampling-for-comparison') }}
        </h3>
        <BQdtCard
          name="globalFilterSamplingForComparison"
          type="filter-sample"
        />
        <div class="filter-hs-code-wrapper">
          <div class="filter-hs-code-scroller">
            <BQdtCard
              name="globalFilterHSCodeForComparison"
              type="filter-hs-code"
            />
          </div>
        </div>
      </div>

      <BQdtCard
        name="globalClearFiltersForComparison"
        type="button"
      />
    </div>

    <div class="main-part">
      <main class="col-sm-12 col-md">
        <div class="h-100 d-flex flex-column">
          <slot />
        </div>
      </main>
    </div>

    <div class="right-part">
      <div class="filters">
        <h3 class="filters-title">
          {{ $t('bi.comparison.filters.title.reference-sample') }}
        </h3>
        <BQdtCard
          name="globalFilterReferenceSample"
          type="filter-sample"
        />
        <div class="filter-hs-code-wrapper">
          <div class="filter-hs-code-scroller">
            <BQdtCard
              name="globalFilterReferenceHSCode"
              type="filter-hs-code"
            />
          </div>
        </div>
      </div>

      <div class="mt-2">
        <div class="text-right">
          <QdtGlobalUpdatedDate />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { QdtGlobalUpdatedDate, BQdtCard } from '@/components/blocks/qdt'
import { BContentHeader } from '@/components/blocks/BContentHeader'
import helpers from '@/helpers'

export default {
  name: 'BViewTemplateTradeComparison',

  components: {
    QdtGlobalUpdatedDate,
    BQdtCard,
    BContentHeader,
  },

  props: {
    page: {
      type: String,
      require: true,
      validator: helpers.validate.qdtHeaderNames,
    },
  },

  watch: {
    page(newValue, oldValue) {
      console.log('newValue', newValue)
      console.log('oldValue', oldValue)
    },
  },

  mounted() {
    console.log('this.page', this.page)
  },
}
</script>

<style scoped lang="sass">
@import "style"
</style>
