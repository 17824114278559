<template>
  <div class="divider" />
</template>

<script>
export default {
  name: 'HeaderDivider',
}
</script>

<style scoped lang="sass">
@import "./style"
</style>
