import Dimensions from './Dimensions'
import DimensionsHeader from './DimensionsHeader'
import DimensionsHelp from './DimensionsHelp'
import DimensionsTopMenu from './DimensionsTopMenu'

export default {
  default: Dimensions,
  header: DimensionsHeader,
  help: DimensionsHelp,
  topMenu: DimensionsTopMenu,
}
