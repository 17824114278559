<template>
  <div class="wrapper">
    {{ $t('bi.open-data.last-updated-at') }}:

    <div>
      {{ lastDate }}
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import helpers from '@/helpers'

export default {
  name: 'BOpenDataUpdated',

  computed: {
    ...mapGetters('openData', [
      'lastModifiedDate',
    ]),
    lastDate() {
      return helpers.date.formatter.stringify(this.lastModifiedDate)
    },
  },
}
</script>

<style scoped lang="sass">
@import "./style"
</style>
