<template>
  <div class="d-flex flex-column">
    <div class="mb-3">
      <UIButton
        type="secondary"
        :href="link"
        target="_blank"
        @click="handleClick"
      >
        {{ $t('bi.open-data.download-full-base') }}
        <UIIcon type="download" />
      </UIButton>
    </div>

    <div class="button-api-doc-wrapper">
      <UIButton
        type="white"
        :href="config.URL_API_DOC"
        target="_blank"
        class="button-api-doc"
        icon="api-code"
      >
        {{ $t('bi.open-data.api-doc') }}
      </UIButton>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { UIButton, UIIcon } from '@/components/ui'
import { config } from '@/config'

export default {
  name: 'BOpenDataFullDB',

  components: { UIIcon, UIButton },

  computed: {
    ...mapGetters('common', [
      'lang',
    ]),
    config() {
      return config
    },
    link() {
      return this.lang === 'uk'
        ? config.URL_DATA_EXPORT_TRADE_FULL_UK
        : config.URL_DATA_EXPORT_TRADE_FULL_EN
    },
  },

  methods: {
    handleClick() {
      this.$ga.event('EXPORT', 'DOWNLOAD_FULL_DUMP', null, null)
    },
  },
}
</script>

<style scoped lang="sass">
@import "./style"
</style>
