<template>
  <UiLayout
    id="app"
    :class="classes"
  >
    <BHeader />

    <UiLayout>
      <UiLayoutSidebar v-show="showSidebar">
        <BSidebarMenu />
      </UiLayoutSidebar>

      <UiLayoutContent>
        <RouterView />
      </UiLayoutContent>
    </UiLayout>

    <BFooter />

    <BGlobalErrorMessage />

    <BModals />
  </UiLayout>
</template>

<script>
import { mapGetters } from 'vuex'
import { UiLayout, UiLayoutContent, UiLayoutSidebar } from '@/components/ui'
import {
  BSidebarMenu,
  BHeader,
  BFooter,
  BGlobalErrorMessage,
} from '@/components/blocks'
import { BModals } from '@/components/blocks/modals'
import helpers from '@/helpers'

export default {
  name: 'App',

  components: {
    BModals,
    UiLayout,
    UiLayoutSidebar,
    UiLayoutContent,
    BSidebarMenu,
    BHeader,
    BFooter,
    BGlobalErrorMessage,
  },

  computed: {
    ...mapGetters('qdt', [
      'isFullscreen',
    ]),
    ...mapGetters('common', [
      'isCollapsedSidebar',
    ]),
    classes() {
      return {
        'no-scrolling': this.isFullscreen,
      }
    },
    showSidebar() {
      const pageType = helpers.page.getType(this.$route)
      return pageType !== 'error'
    },
  },

  watch: {
    isCollapsedSidebar() {
      this.handleResizeQdt()
    },
  },

  created() {
    window.addEventListener('resize', this.handleResizeQdt)
  },

  destroyed() {
    window.removeEventListener('resize', this.handleResizeQdt)
  },

  methods: {
    handleResizeQdt(time = 300) {
      const pageType = helpers.page.getType(this.$route)

      if (!['trade', 'complaints'].includes(pageType)) return

      setTimeout(() => {
        if (
          this.$qdt.globals.resize
        ) {
          this.$qdt.globals.resize()
        }
      }, time)
    },
  },
}
</script>

<style lang="sass">
@import '~@/styles/includes/reset'
@import '~@/styles/common'
@import '~@/styles/animations'
@import '~@/styles/includes/multiselect-overwrite'
@import '~@/styles/includes/vue2-datepicker-override'
@import '~@/styles/includes/vue-js-modal'
@import '~@/styles/includes/qdt-overwrite'
</style>
