export const QDT_TYPES = {
  DEFAULT: 'default',
  TABLE: 'table',
  BUTTON: 'button',
  FILTER_SAMPLE: 'filter-sample',
  FILTER_HS_CODE: 'filter-hs-code',
  HEADER_ITEM: 'header-item',
  TOP_MENU_ITEM: 'top-menu-item',
  HELP: 'help',
}

export const QDT_HEADER_NAMES = {
  trade: 'header',
  complaints: 'headerComplaints',
}

const createQdt = (id, height = '100%', props = {}) => ({
  type: 'QdtViz',
  props: {
    id,
    height,
    ...props,
  },
})

const createQdtTable = id => createQdt(id, '100%', {
  exportData: true,
  exportDataOptions: {
    format: 'OOXML',
    state: 'P',
  },
})

const QDT_CURRENT_SELECTIONS = {
  type: 'QdtCurrentSelections',
  props: { height: 'auto' },
}

// const QDT_PARTNERS = {
//   type: 'QdtViz',
//   props: {
//     id: 'PBZdpP',
//     height: '24px',
//     width: '122px',
//   },
// }

export const QDTS = {
  header: QDT_CURRENT_SELECTIONS,
  headerComplaints: QDT_CURRENT_SELECTIONS,

  partners: createQdt('PBZdpP'),

  globalLang: createQdt('DYKZmmV'),
  globalLangComplaints: createQdt('mwmfQ'),

  globalFilterReferenceSample: createQdt('baExhjh'),
  globalFilterReferenceHSCode: createQdt('kFshYm'),
  globalClearFiltersReference: createQdt('AVbpJmr'),

  globalFilterSamplingForComparison: createQdt('wxmzG'),
  globalFilterHSCodeForComparison: createQdt('epeSqBx'),
  globalClearFiltersForComparison: createQdt('upMpVZ'),

  globalComparisons: createQdt('TUxxSH', '30px'),
  globalVSYears: createQdt('d152c891-f112-44f0-919b-b9e6bf10f6d0'),

  globalUpdatedDate: createQdt('2959d350-5f10-4593-b406-ed3ed68573f1', '25px'),
  globalUpdatedDateComplaints: createQdt('YfamWHE', '31px'),

  // Home
  homeHelp: createQdt('8b36da1f-39de-433e-91ae-3e104c779f4a'),
  homeKPI1: createQdt('836f7083-1a35-4286-aa74-7e4eccf7e36e'),
  homeKPI2: createQdt('2c350995-1aa5-40dc-a72b-965ce4a903e9'),
  homeKPI3: createQdt('e5324e54-d987-44b8-b570-7ae7a6def85a'),
  homeKPI4: createQdt('bcbc2708-9472-46b2-8e4c-084363f820af'),
  homeDyn1: createQdt('acdbafd5-8422-49a6-9e45-feb3d071b7ee'),
  homeDyn2: createQdt('jUECTAh'),
  homeTreeMap1: createQdt('c167f3b6-e812-4a97-addc-f47e2172e325'),
  homeTreeMap2: createQdt('101ffd7a-62a6-441b-8e4d-b721c42d77d5'),

  // Payments
  paymHelp: createQdt('PPsT'),
  paymKPI1: createQdt('JRspRm'),
  paymKPI2: createQdt('eNZwqu'),
  paymKPI3: createQdt('GddXvv'),
  paymKPI4: createQdt('RZpTwe'),
  paymKPI5: createQdt('dZJbUyC'),
  paymKPI6: createQdt('pRZVhN'),
  paymDyn1: createQdt('JUeUzBT'),
  paymDyn2: createQdt('MCuJgqC'),
  paymTreeMap: createQdt('WDRvXm'),

  // Imex
  imexTitle: createQdt('jHuTWMp'),
  imexHelp: createQdt('pmXzxCL'),
  imexTable: createQdtTable('kVyd'),
  imexIm: createQdt('VbwgvK'),
  imexEx: createQdt('WMJGmT'),
  imexMapIm: createQdt('VTNzrYK'),
  imexMapEx: createQdt('PsZwdA'),

  // Balance
  balanceTitle: createQdt('MEYtYBb'),
  balanceHelp: createQdt('mrhaFpH'),
  balanceTable: createQdtTable('kJrjn'),
  balanceMap: createQdt('dthep'),
  balanceCountry: createQdt('LRNmj'),

  // Turnover
  turnoverTitle: createQdt('LNHBs'),
  turnoverHelp: createQdt('bnPdsK'),
  turnoverDim: createQdt('XXsCK'),
  turnoverMes: createQdt('FnWSDf'),
  turnoverTable: createQdtTable('WjNJWF'),
  turnoverCountry: createQdt('JMzapQ'),
  turnoverIm: createQdt('WZkZsnb'),
  turnoverDyn: createQdt('DJSAnbz'),
  turnoverBP: createQdt('ypCftEU'),

  // Ratings
  ratingsTitle: createQdt('cePNUKV'),
  ratingsHelp: createQdt('xPPWPVT'),
  ratingsDimension: createQdt('fdAJv'),
  ratingsIndicator: createQdt('whqzhz'),
  ratingsTopN: createQdt('nTPxAd'),
  ratingsBTop: createQdt('TqmyHS'),
  ratingsBButton: createQdt('KheyrrX'),
  ratingsBTable: createQdtTable('jvDDWK'),
  ratingsWTop: createQdt('VpHwMDN'),
  ratingsWButton: createQdt('EmKpc'),
  ratingsWTable: createQdtTable('ZpcR'),

  // Comparison
  comparisonTitle: createQdt('yuDQcfZ'),
  comparisonHelp: createQdt('JpFBP'),
  comparisonCurr: createQdt('mZjKAyT'),
  comparisonVol: createQdt('QFHkYh'),
  comparisonDim: createQdt('WgJgtP'),
  comparisonMes: createQdt('HLnUaZN'),
  comparisonUn: createQdt('WVapjcG'),
  comparisonTable: createQdtTable('whsUkx'),
  comparisonIm: createQdt('KzubLvp'),
  comparisonDev: createQdt('MCPzDG'),

  // Turnoverhs
  turnoverhsTitle: createQdt('vfGpL'),
  turnoverhsHelp: createQdt('ZnKP'),
  turnoverhsTType: createQdt('PcscJ'),
  turnoverhsTDim: createQdt('UUVMr'),
  turnoverhsTable: createQdtTable('gcHWfSP'),

  // Analytics
  analyticsTitle: createQdt('PunZBg'),
  analyticsHelp: createQdt('VHma'),
  analyticsDim: createQdt('vEGVm'),
  analyticsMes: createQdt('kMVxw'),
  analyticsTable: createQdtTable('pygU'),
  analyticsCountry: createQdt('atCRMa'),
  analyticsPC: createQdt('KsRyF'),
  analyticsDyn: createQdt('CpzDmQ'),

  // Complaints Home
  complaintsHomeLFLType: createQdt('smFQScJ'),
  complaintsHomeMain: createQdt('PKnWC'),
  complaintsHomeHelp: createQdt('baFYaX'),
  complaintsHomeKPI1: createQdt('sJdKhwD'),
  complaintsHomeKPI2: createQdt('zLtLaGx'),
  complaintsHomeKPI3: createQdt('mEdmz'),
  complaintsHomeKPI4: createQdt('JbYRpmb'),
  complaintsHomeKPI5: createQdt('SJprpP'),
  complaintsHomeMap: createQdt('yMaQy'),
  complaintsHomeHeatMap: createQdt('EmJPC'),

  // Complaints Analytics
  complaintsAnalyticsTitle: createQdt('PLvg'),
  complaintsAnalyticsHelp: createQdt('649b1d1c-3a41-4ca9-bd3d-fdb45788220c'),
  complaintsAnalyticsTable: createQdt('3537f697-0564-4fb7-9201-ef35a1cedd59'),
  complaintsAnalyticsContainer: createQdt('duDyaP'),
  complaintsAnalyticsIndicator:
    createQdt('d6e1221e-963d-49e9-85f9-d876983d5ea9', '60px'),
  complaintsAnalyticsDim:
    createQdt('6b962f11-9b7c-4c3f-9103-3e6f45dc6ffc', '60px'),
  complaintsAnalyticsFilters: createQdt('36c55ca3-6144-416f-a295-93ff46e67955'),
  complaintsFiltersTopN: createQdt('tPFjASv'),
  complaintsAnalyticsCusts: createQdt('99481d7e-89ff-4d21-9626-002a216f4e4f'),
  complaintsAnalyticsClients: createQdt('4c45cb71-9569-4d63-a964-b5bd2c93984f'),
  complaintsAnalyticsDynamics:
    createQdt('de14e236-8dd4-4291-83c4-0716a0ff34be'),

  // Complaints Ratings
  complaintsRatingsTitle: createQdt('RfPwHD'),
  complaintsRatingsHelp: createQdt('TPydT'),
  complaintsRatingsRBTop: createQdt('HvGmWxQ'),
  complaintsRatingsRBButton: createQdt('vdNXR'),
  complaintsRatingsRBTable: createQdt('UnjEtcK'),
  complaintsRatingsRWButton: createQdt('QjZLW'),
  complaintsRatingsRWTable: createQdt('CMLjj'),
  complaintsRatingsRWTop: createQdt('RvZAX'),
  complaintsRatingsRIndicator: createQdt('VSpCbp'),
  complaintsRatingsRDimension: createQdt('FCWXvp'),
  complaintsRatingsRFilters: createQdt('XekzQ'),

}
