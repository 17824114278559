<template>
  <div class="h-100 d-flex flex-column">
    <div class="row flex-grow-1">
      <div class="col-12">
        <BQdtCard
          name="comparisonTable"
          type="table"
          with-fullscreen
        />
      </div>
    </div>

    <div class="row flex-grow-1">
      <div class="col-sm-12 col-md-7 col-lg-8 col-xl-8">
        <BQdtCard
          name="comparisonIm"
          with-fullscreen
        />
      </div>
      <div class="col-sm-12 col-md-5 col-lg-4 col-xl-4">
        <BQdtCard
          name="comparisonDev"
          with-fullscreen
        />
      </div>
    </div>
  </div>
</template>

<script>
import { BQdtCard } from '@/components/blocks'

export default {
  name: 'Comparison',

  components: { BQdtCard },
}
</script>
