import moment from 'moment'
import { config } from '@/config'

export const formatter = {
  stringify: date => {
    return date ? moment(date).format(config.DATE_SHORT_FORMAT) : ''
  },
  parse: value => {
    return value ? moment(value, config.DATE_SHORT_FORMAT).toDate() : null
  },
}

export const formatterOnlyMonth = {
  stringify: date => {
    return date ? moment(date).format(config.DATE_FORMAT_ONLY_MONTH) : ''
  },
  parse: value => {
    return value ? moment(value, config.DATE_FORMAT_ONLY_MONTH).toDate() : null
  },
}

export const parseOpenDataDate = date => {
  return date ? moment(date).format('MM.YYYY') : ''
}

export const disabledPast = startDate => value => {
  const date = new Date(value)
  date.setDate(date.getDate())
  const todayTimestamp = new Date(startDate).getTime()

  return todayTimestamp > date.getTime()
}

export const disabledFeature = endDate => value => {
  const date = new Date(value)
  date.setDate(date.getDate())
  const todayTimestamp = new Date(endDate).getTime()

  return todayTimestamp < date.getTime()
}
