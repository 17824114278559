import Vue from 'vue'
import VueRouter from 'vue-router'

import { ROUTE_NAMES } from '@/constants'
import { config } from '@/config'

import trade from '@/views/trade'
import complaints from '@/views/complaints'
import OpenData from '@/views/OpenData'
import Page404 from '@/views/Page404'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    beforeEnter: (to, from, next) => {
      next({
        name: ROUTE_NAMES.TRADE.HOME,
        params: {
          lang: config.FALLBACK_LANGUAGE,
        },
      })
    },
  },
  {
    path: '/:lang/trade',
    name: ROUTE_NAMES.TRADE.INDEX,
    component: trade.Wrapper,
    children: [
      {
        path: '/',
        name: ROUTE_NAMES.TRADE.HOME,
        components: trade.home,
        meta: {
          config: {
            showTradeFilters: true,
          },
        },
      },
      {
        path: 'payments',
        name: ROUTE_NAMES.TRADE.PAYMENTS,
        components: trade.payments,
        meta: {
          config: {
            showTradeFilters: true,
          },
        },
      },

      {
        path: 'import-export',
        name: ROUTE_NAMES.TRADE.IMPORT_EXPORT,
        components: trade.importExport,
        meta: {
          config: {
            showTradeFilters: true,
          },
        },
      },
      {
        path: 'balance',
        name: ROUTE_NAMES.TRADE.BALANCE,
        components: trade.balance,
        meta: {
          config: {
            showTradeFilters: true,
          },
        },
      },
      {
        path: 'dimensions',
        name: ROUTE_NAMES.TRADE.DIMENSIONS,
        components: trade.dimensions,
        meta: {
          config: {
            showTradeFilters: true,
          },
        },
      },
      {
        path: 'ratings',
        name: ROUTE_NAMES.TRADE.RATINGS,
        components: trade.ratings,
        meta: {
          config: {
            showTradeFilters: true,
          },
        },
      },
      {
        path: 'comparison',
        name: ROUTE_NAMES.TRADE.COMPARISON,
        components: trade.comparison,
        meta: {
          config: {
            showTradeFilters: true,
          },
        },
      },
      {
        path: 'value-of-goods-hs-code',
        name: ROUTE_NAMES.TRADE.VALUE_OF_GOODS_HS_CODE,
        components: trade.valueOfGoodsHSCode,
        meta: {
          config: {
            showTradeFilters: true,
          },
        },
      },
      {
        path: 'customs-value',
        name: ROUTE_NAMES.TRADE.CUSTOMS_VALUE,
        components: trade.customsValue,
        meta: {
          config: {
            showTradeFilters: true,
          },
        },
      },
    ],
  },
  {
    path: '/:lang/complaints',
    name: ROUTE_NAMES.COMPLAINTS.INDEX,
    component: complaints.Wrapper,
    children: [
      {
        path: '/',
        name: ROUTE_NAMES.COMPLAINTS.HOME,
        components: complaints.home,
        meta: {
          config: {
            showComplaintsFilters: true,
          },
        },
      },
      {
        path: 'ratings',
        name: ROUTE_NAMES.COMPLAINTS.RATINGS,
        components: complaints.ratings,
        meta: {
          config: {
            showComplaintsFilters: true,
          },
        },
      },
      {
        path: 'analytics',
        name: ROUTE_NAMES.COMPLAINTS.ANALYTICS,
        components: complaints.analytics,
        meta: {
          config: {
            showComplaintsFilters: true,
          },
        },
      },
    ],
  },
  {
    path: '/:lang/export-bi-trade-data',
    name: ROUTE_NAMES.OPEN_DATA,
    component: OpenData,
  },
  {
    path: '*/*',
    beforeEnter: (to, from, next) => {
      const [, lang, ...otherPath] = to.path.split('/')
      const hasLang = config.AVAILABLE_LANGUAGES.includes(lang)
      const pathMatch = hasLang ? `/${otherPath.join('/')}` : to.path

      next({
        name: ROUTE_NAMES.ERROR_404,
        params: {
          lang: from.params.lang || 'uk',
          pathMatch,
        },
      })
    },
  },
  {
    path: '/:lang/*',
    name: ROUTE_NAMES.ERROR_404,
    component: Page404,
  },
]

const router = new VueRouter({
  routes,
  mode: 'history',
})

export default router
