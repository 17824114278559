<template>
  <div class="h-100 d-flex flex-column">
    <div class="row flex-grow-1">
      <BQdtCard
        class="col-sm-12 col-md-6 col-lg"
        name="homeKPI1"
      />
      <BQdtCard
        class="col-sm-12 col-md-6 col-lg"
        name="homeKPI2"
      />
      <BQdtCard
        class="col-sm-12 col-md-6 col-lg"
        name="homeKPI3"
      />
      <BQdtCard
        class="col-sm-12 col-md-6 col-lg"
        name="homeKPI4"
      />
    </div>

    <div class="row flex-grow-2">
      <div class="col-sm-12 col-md-6 col-lg-9">
        <div class="row h-100">
          <div class="col-12 flex-grow-1">
            <BQdtCard
              name="homeDyn1"
              with-fullscreen
            />
          </div>
          <div class="col-12 flex-grow-1">
            <BQdtCard
              name="homeDyn2"
              with-fullscreen
            />
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-md-6 col-lg-3">
        <div class="row h-100">
          <div class="col-12 flex-grow-1">
            <BQdtCard
              name="homeTreeMap1"
              with-fullscreen
            />
          </div>
          <div class="col-12 flex-grow-1">
            <BQdtCard
              name="homeTreeMap2"
              with-fullscreen
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BQdtCard } from '@/components/blocks'

export default {
  name: 'Home',

  components: { BQdtCard },
}
</script>
