import { isEmpty } from 'lodash'

import {
  BUTTON_TYPES,
  SELECT_MENU_CONTENT_POSITIONS,
  SELECT_MENU_POSITIONS,
  ICON_TYPES,
  QDT_TYPES,
  QDTS,
  TRADE_ROUTE_NAMES,
  COMPLAINTS_ROUTE_NAMES,
  QDT_HEADER_NAMES,
  ROUTE_NAMES,
  BUTTON_SIZES,
} from '@/constants'

export const isEmptyValue = val => {
  return isEmpty(JSON.parse(JSON.stringify(val || '')))
}

export const tuple = validData => value => validData.includes(value)

export const iconType = value => Object
  .values(ICON_TYPES)
  .includes(value)

export const routeName = value => [
  ...Object.values(TRADE_ROUTE_NAMES),
  ...Object.values(COMPLAINTS_ROUTE_NAMES),
  ...Object.values(ROUTE_NAMES),
].includes(value)

export const buttonType = value => Object
  .values(BUTTON_TYPES)
  .includes(value)

export const buttonSize = value => Object
  .values(BUTTON_SIZES)
  .includes(value)

export const qdtName = value => Object
  .keys(QDTS)
  .includes(value)

export const qdtType = value => Object
  .values(QDT_TYPES)
  .includes(value)

export const qdtHeaderNames = value => Object
  .keys(QDT_HEADER_NAMES)
  .includes(value)

export const select = {
  menuPosition: value => Object
    .values(SELECT_MENU_POSITIONS)
    .includes(value),

  menuContentPosition: value => Object
    .values(SELECT_MENU_CONTENT_POSITIONS)
    .includes(value),

  options: options => !options.length || options.every(option => (
    'label' in option
    && 'value' in option
  )),
}
