<template>
  <div>
    <BModalPartners />
  </div>
</template>

<script>
import { BModalPartners } from './modalPartners'

export default {
  name: 'BModals',

  components: { BModalPartners },
}
</script>
