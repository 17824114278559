export const getType = route => {
  const page = route.matched.map(item => item.name)

  if (page.includes('trade')) return 'trade'
  if (page.includes('complaints')) return 'complaints'

  if (page.some(item => item?.includes('error'))) return 'error'

  return 'other'
}
