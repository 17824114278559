export default {
  namespaced: true,
  state: {
    isFullscreen: false,
  },
  getters: {
    isFullscreen: state => state.isFullscreen,
  },
  mutations: {
    setFullscreen(state, status) {
      state.isFullscreen = status
    },
  },
  actions: {
  },
}
