<template>
  <BViewTemplate page="complaints">
    <template #header>
      <RouterView name="header" />
    </template>

    <template #help>
      <RouterView name="help" />
    </template>

    <template #top-menu>
      <RouterView name="topMenu" />
    </template>

    <RouterView />
  </BViewTemplate>
</template>

<script>
import { BViewTemplate } from '@/components/blocks'

export default {
  name: 'Wrapper',

  components: {
    BViewTemplate,
  },
}
</script>
