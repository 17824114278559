<template>
  <div class="h-100 d-flex flex-column">
    <div class="row flex-grow-1">
      <div class="col-sm-12 col-md-8 col-lg-9 col-xl-9">
        <BQdtCard
          name="imexTable"
          type="table"
          with-fullscreen
        />
      </div>
      <div class="col-sm-12 col-md-4 col-lg-3 col-xl-3">
        <div class="row h-100">
          <div class="col-12 flex-grow-1">
            <BQdtCard
              name="imexIm"
              with-fullscreen
            />
          </div>
          <div class="col-12 flex-grow-1">
            <BQdtCard
              name="imexEx"
              with-fullscreen
            />
          </div>
        </div>
      </div>
    </div>

    <div class="row flex-grow-1">
      <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <BQdtCard
          name="imexMapIm"
          with-fullscreen
        />
      </div>
      <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <BQdtCard
          name="imexMapEx"
          with-fullscreen
        />
      </div>
    </div>
  </div>
</template>

<script>
import { BQdtCard } from '@/components/blocks'

export default {
  name: 'ImportExport',

  components: { BQdtCard },
}
</script>
