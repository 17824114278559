<template>
  <BQdtCard
    name="ratingsHelp"
    type="help"
  />
</template>

<script>
import { BQdtCard } from '@/components/blocks'

export default {
  name: 'RatingsHelp',

  components: { BQdtCard },
}
</script>
