<template>
  <UiLayoutHeader class="header">
    <HeaderLogo />
    <HeaderDivider />
    <HeaderTitle class="d-none d-sm-block" />

    <template slot="extra">
      <div class="d-none d-sm-block">
        <UIButton class="d-none">
          {{ $t('bi.header.help') }}
        </UIButton>
        <CButtonFeedback />
      </div>

      <BLanguage />
    </template>
  </UiLayoutHeader>
</template>

<script>
import { UiLayoutHeader, UIButton } from '@/components/ui'
import { CButtonFeedback } from '@/components/containers'
import { BLanguage } from '@/components/blocks/BLanguage'

import { HeaderTitle, HeaderLogo, HeaderDivider } from './components'

export default {
  name: 'BHeader',

  components: {
    CButtonFeedback,
    UIButton,
    UiLayoutHeader,
    BLanguage,
    HeaderTitle,
    HeaderLogo,
    HeaderDivider,
  },
}
</script>

<style scoped lang="sass">
@import "./style"
</style>
