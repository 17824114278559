export const findAndInteractsWithBlock = (selector, callback) => {
  const findBlock = () => document.querySelector(selector)

  const intervalBlock = setInterval(() => {
    const block = findBlock()

    if (block) {
      clearInterval(intervalBlock)
      callback(block)
    }
  }, 500)
}

export const toggleBlock = (display, selector, parent) => {
  findAndInteractsWithBlock(selector, block => {
    const foundBlock = block

    if (parent) {
      foundBlock.closest(parent).style.display = display
      return
    }

    foundBlock.style.display = display
  })
}
