<template>
  <div class="row flex-grow-1">
    <div class="col d-flex flex-column">
      <div class="row flex-grow-1">
        <div class="col-lg-7">
          <BQdtCard
            name="complaintsAnalyticsTable"
            type="table"
            with-fullscreen
          />
        </div>
        <div class="col-lg-5">
          <BQdtCard
            name="complaintsAnalyticsContainer"
            with-fullscreen
          />
        </div>
      </div>
      <div class="row flex-grow-1">
        <div class="col-lg-5">
          <BQdtCard
            name="complaintsAnalyticsCusts"
            with-fullscreen
          />
        </div>
        <div class="col-lg-2">
          <BQdtCard
            name="complaintsAnalyticsClients"
            with-fullscreen
          />
        </div>
        <div class="col-lg-5">
          <BQdtCard
            name="complaintsAnalyticsDynamics"
            with-fullscreen
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BQdtCard } from '@/components/blocks'

export default {
  name: 'Analytics',

  components: { BQdtCard },
}
</script>
