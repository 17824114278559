<template>
  <transition name="fade">
    <div
      v-if="errorMessage"
      class="error-message"
      @click="hideErrorMessage"
    >
      <UIIcon type="alert" />
      <span
        id="global-error-message-text"
        v-html="message"
      />
    </div>
  </transition>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { UIIcon } from '@/components/ui'

export default {
  name: 'BGlobalErrorMessage',

  components: { UIIcon },

  computed: {
    ...mapGetters('common', [
      'errorMessage',
    ]),
    message() {
      if (Array.isArray(this.errorMessage)) {
        return this.errorMessage.map(msg => this.$t(msg)).join('<br>')
      }
      return this.$t(this.errorMessage)
    },
  },

  methods: {
    ...mapMutations('common', [
      'hideErrorMessage',
    ]),
  },
}
</script>

<style scoped lang="sass">
@import "./style"
</style>
