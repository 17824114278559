<template>
  <UIDatePicker
    v-model="formValues.endPeriod"
    :error="errors.endPeriod"
    :placeholder="$t('bi.field.placeholder.choose-period')"
    :disabled-date="disabledDate"
    only-month
    @change="handleChange"
  />
</template>

<script>
import { mapGetters } from 'vuex'
import { UIDatePicker } from '@/components/ui'
import helpers from '@/helpers'
import { updateOpenDataValuesMixin } from '@/mixins'
import { OPEN_DATA_MIN_DATE_OF_EXPORT } from '@/constants'

export default {
  name: 'EndPeriod',

  components: { UIDatePicker },

  mixins: [updateOpenDataValuesMixin],

  computed: {
    ...mapGetters('openData', [
      'lastModifiedDate',
    ]),
    disabledDate() {
      return value => {
        const { startPeriod } = this.formValues
        const { disabledPast, disabledFeature } = helpers.date

        if (!startPeriod) {
          if (this.lastModifiedDate) {
            return disabledPast(OPEN_DATA_MIN_DATE_OF_EXPORT)(value)
              || disabledFeature(new Date(this.lastModifiedDate))(value)
          }
          return disabledPast(OPEN_DATA_MIN_DATE_OF_EXPORT)(value)
        }

        if (this.lastModifiedDate) {
          return disabledPast(startPeriod)(value)
            || disabledFeature(new Date(this.lastModifiedDate))(value)
        }
        return disabledPast(startPeriod)(value)
      }
    },
  },

  methods: {
    handleChange(value) {
      this.setFormValue({
        name: 'endPeriod',
        value,
      })
    },
  },

}
</script>
