import * as common from './coomon'
import * as validate from './validate'
import * as page from './page'
import * as date from './date'
import * as file from './file'

const helpers = {
  common,
  validate,
  page,
  date,
  file,
}

export default helpers
