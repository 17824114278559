<template>
  <BQdtCard
    name="turnoverhsHelp"
    type="help"
  />
</template>

<script>
import { BQdtCard } from '@/components/blocks'

export default {
  name: 'ValueOfGoodsHSCodeHelp',

  components: { BQdtCard },
}
</script>
