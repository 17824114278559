<template>
  <UIButton @click="handleClick">
    {{ $t('bi.footer.partners') }}
  </UIButton>
</template>

<script>
import { UIButton } from '@/components/ui'

export default {
  name: 'BButtonModalPartners',

  components: { UIButton },

  methods: {
    handleClick() {
      this.$modal.show('partners')
    },
  },
}
</script>
