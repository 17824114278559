var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Multiselect',{staticClass:"select",class:_vm.classes,attrs:{"value":_vm.value,"multiple":_vm.multiple,"options":_vm.options,"searchable":false,"close-on-select":!_vm.multiple,"show-labels":false,"placeholder":_vm.placeholder,"track-by":"value","label":"label"},on:{"input":_vm.handleChange},scopedSlots:_vm._u([{key:"caret",fn:function(){return [_c('UIIcon',{staticClass:"multiselect__select",attrs:{"type":"arrow-down-medium"}})]},proxy:true},{key:"singleLabel",fn:function(ref){
var option = ref.option;
return [_vm._v(" "+_vm._s(_vm.getOptionLabel(option))+" ")]}},{key:"selection",fn:function(ref){
var values = ref.values;
var isOpen = ref.isOpen;
return [(values.length && !isOpen)?_c('span',{staticClass:"multiselect__single",attrs:{"title":_vm.getValues(values)}},[_vm._v(" "+_vm._s(_vm.getValues(values))+" ")]):_vm._e()]}},{key:"tag",fn:function(ref){
var option = ref.option;
return [_vm._v(" "+_vm._s(_vm.getOptionLabel(option))+", ")]}},{key:"beforeList",fn:function(){return [_c('div',{staticClass:"select-actions"},[_c('UIButton',{on:{"click":_vm.selectAll}},[_vm._v(" "+_vm._s(_vm.$t('bi.button.select-all'))+" ")]),_c('UIButton',{on:{"click":_vm.deselect}},[_vm._v(" "+_vm._s(_vm.$t('bi.button.deselect'))+" ")]),_c('UIButton',{attrs:{"icon":"arrow-double-left-right"},on:{"click":_vm.invertSelect}})],1)]},proxy:true},{key:"option",fn:function(ref){
var option = ref.option;
return [(_vm.multiple)?_c('UICheckbox',{staticClass:"option-checkbox",attrs:{"checked":_vm.checkActiveOption(option)},on:{"click":function($event){return _vm.handleClickCheckbox(option)}}}):_vm._e(),_c('span',{staticClass:"option-label",attrs:{"title":_vm.getOptionLabel(option)}},[_vm._v(" "+_vm._s(_vm.getOptionLabel(option))+" ")])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }