import { isEmpty } from 'lodash'
import moment from 'moment'
import { event } from 'vue-analytics'

import api from '@/services/api'
import helpers from '@/helpers'
// import axios from 'axios'

const DEFAULT_FORM_VALUES = {
  startPeriod: null,
  endPeriod: null,
  sections: [],
  rates: [],
  timeInterval: 'month',
  currency: 'USD',
}

// const I18N_CODES_BY_LOC = {
//   currency: 'bi.field.currency',
//   timeInterval: 'bi.field.data-grouping',
//   groupBy: 'bi.field.dimensions',
//   indicators: 'bi.field.kpi',
// }

export default {
  namespaced: true,
  state: {
    status: '',
    formValues: { ...DEFAULT_FORM_VALUES },
    errors: {},
    options: {},
    report: null,
    lastModifiedDate: null,
  },
  getters: {
    status: state => state.status,
    formValues: state => state.formValues,
    errors: state => state.errors,
    options: state => state.options,
    lastModifiedDate: state => state.lastModifiedDate,
  },
  mutations: {
    setStatus(state, status) {
      if (!status) {
        state.report = null
      }
      state.status = status
    },
    clearStatus(state) {
      state.status = ''
    },
    setFormValue(state, { name, value }) {
      state.formValues[name] = value
      state.errors[name] = helpers.validate.isEmptyValue(value)
      localStorage.setItem('formValues', JSON.stringify(state.formValues))
    },
    resetFormValues(state) {
      state.formValues = { ...DEFAULT_FORM_VALUES }
      state.errors = {}
      localStorage.setItem('formValues', JSON.stringify(state.formValues))
    },
    setOptions(state, options) {
      state.options = options || {}
    },
    setLastModifiedDate(state, date) {
      state.lastModifiedDate = date
    },
    setReport(state, report) {
      state.report = report
    },
  },
  actions: {
    async loadOptions({ commit, dispatch }) {
      const {
        data: {
          lastModifiedDate,
          ...options
        },
      } = await api.openData.loadOptions().catch(err => err)

      commit('setOptions', options || [])
      commit('setLastModifiedDate', lastModifiedDate)
      dispatch('setFormValuesFromLocalStorage')
    },
    setFormValuesFromLocalStorage({ state }) {
      const data = JSON.parse(localStorage.formValues || null)

      if (!data) return

      state.formValues = {
        ...data,
        startPeriod: data.startPeriod && new Date(data.startPeriod),
        endPeriod: data.endPeriod && new Date(data.endPeriod),
      }
    },
    resetFormValues({ commit }) {
      commit('resetFormValues')
    },
    async createSample({
      commit,
      state,
      rootGetters,
      // dispatch,
    }) {
      const errors = Object.entries(state.formValues)
        .reduce((acc, [key, value]) => {
          const isValid = !helpers.validate.isEmptyValue(value)
          if (!isValid) acc[key] = true
          return acc
        }, {})

      if (!isEmpty(errors)) {
        state.errors = errors
        commit(
          'common/showErrorMessage',
          'bi.error.missing-fields',
          { root: true },
        )
        return
      }

      // commit('setStatus', 'loading')

      const {
        startPeriod,
        endPeriod,
        sections,
        timeInterval,
        rates,
        currency,
      } = state.formValues
      const { parseOpenDataDate } = helpers.date
      const start = parseOpenDataDate(startPeriod)
      const end = parseOpenDataDate(endPeriod)
      const groupBy = sections?.map(item => item.value).join(',')
      const indicators = rates?.map(item => item.value).join(',')
      const lang = rootGetters['common/lang'].toUpperCase()

      const params = {
        dateRange: `${start}-${end}`,
        timeInterval,
        groupBy,
        indicators,
        currency,
      }

      const { data } = await api.openData.createSample(lang, params)
        .catch(err => err.response)

      // if (data.errors) {
      //   const { code, loc } = data.errors[0]
      //   const i18nCodeLoc = I18N_CODES_BY_LOC[loc]
      //   const message = i18nCodeLoc ? [i18nCodeLoc, code] : code
      //
      //   if (code === 'bi.error.heavy-query') {
      //     commit('setStatus', 'errorHeavyQuery')
      //   } else {
      //     commit('common/showErrorMessage', message, { root: true })
      //     commit('setStatus', 'error')
      //   }
      //   return
      // }

      if (!state.status) return

      commit('setLink', data)

      // commit('setReport', data)
      // commit('setStatus', 'success')
      // dispatch('downloadReport', params.dateRange)
    },
    downloadReport({ state, rootGetters }, dateRange) {
      if (!state.report) return

      const lang = rootGetters['common/lang'].toUpperCase()
      const dateToday = moment().format('DDMMYY')

      event('EXPORT', 'DOWNLOAD_SAMPLE', null, null)
      helpers.file.downloadCSV(
        state.report,
        `BI_Statistic_Trade data_${dateRange}_${lang}_${dateToday}.csv`,
      )
    },
  },
}
