<template>
  <section class="view-wrapper">
    <div class="main-part">
      <BContentHeader :page="page" />

      <div>
        <slot name="header" />
      </div>

      <div
        v-if="hasTopMenu"
        class="top-menu mb-4"
      >
        <slot name="top-menu" />
      </div>

      <main class="col-sm-12 col-md">
        <div class="h-100 d-flex flex-column">
          <slot />
        </div>
      </main>
    </div>

    <div
      class="right-part"
      :class="classesRightPart"
    >
      <div class="actions">
        <UIButton
          :icon="iconToggleFilters"
          size="small"
          @click="toggleFilters"
        />
        <slot name="help" />
      </div>

      <div
        v-if="config.showTradeFilters"
        class="filters"
        :class="filtersClasses"
      >
        <div class="filter-wrapper">
          <div class="filter-scroller">
            <BQdtCard
              name="globalFilterReferenceSample"
              type="filter-sample"
            />
            <BQdtCard
              class="mt-4"
              name="globalFilterReferenceHSCode"
              type="filter-hs-code"
            />
          </div>
        </div>
      </div>

      <div
        v-if="hasComplaintsFilters"
        class="filters"
      >
        <slot name="complaints-filters" />
      </div>

      <div
        v-if="config.showComplaintsFilters"
        class="filters"
      >
        <BQdtCard name="complaintsAnalyticsIndicator" />
        <BQdtCard name="complaintsAnalyticsDim" />
        <div class="complaints-filters-wrapper">
          <BQdtCard name="complaintsAnalyticsFilters" />
        </div>
        <!--        <BQdtCard name="complaintsFiltersTopN" />-->
      </div>

      <div class="mt-2">
        <div class="text-right">
          <QdtGlobalUpdatedDate />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { QdtGlobalUpdatedDate, BQdtCard } from '@/components/blocks/qdt'
import { BContentHeader } from '@/components/blocks/BContentHeader'
import { UIButton } from '@/components/ui'
import helpers from '@/helpers'

export default {
  name: 'BViewTemplate',

  components: {
    UIButton,
    QdtGlobalUpdatedDate,
    BQdtCard,
    BContentHeader,
  },

  props: {
    page: {
      type: String,
      require: true,
      validator: helpers.validate.qdtHeaderNames,
    },
  },

  computed: {
    ...mapGetters('common', [
      'isCollapsedFilters',
    ]),
    hasTopMenu() {
      return this.$slots['top-menu']
    },
    hasComplaintsFilters() {
      return this.$slots['complaints-filters']
    },
    filtersClasses() {
      return {
        'with-title': this.config.showTradeFiltersTitle,
      }
    },
    config() {
      return this.$route.meta.config || {}
    },
    classesRightPart() {
      return {
        collapsed: this.isCollapsedFilters,
      }
    },
    iconToggleFilters() {
      return this.isCollapsedFilters ? 'back' : 'inside'
    },
  },

  methods: {
    ...mapMutations('common', [
      'setCollapsedFilters',
    ]),
    toggleFilters() {
      this.setCollapsedFilters(!this.isCollapsedFilters)
    },
  },
}
</script>

<style scoped lang="sass">
@import "style"
</style>
