<template>
  <div class="badge-wrapper">
    <slot />
    <div
      v-if="text"
      class="badge"
    >
      {{ $t(text) }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'UIBadge',

  props: {
    text: {
      type: String,
      default: null,
    },
  },
}
</script>

<style scoped lang="sass">
@import "./style"
</style>
