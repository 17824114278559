import moment from 'moment'

export default {
  namespaced: true,
  state: {
    lang: undefined,
    error: {
      message: undefined,
      timer: undefined,
    },
    isCollapsedSidebar: JSON.parse(localStorage.isCollapsedSidebar || false),
    isCollapsedFilters: false,
    openedSideBars: JSON.parse(localStorage.openedSideBars || '[]'),
  },
  getters: {
    lang: state => state.lang,
    errorMessage: state => state.error.message,
    isCollapsedSidebar: state => state.isCollapsedSidebar,
    isCollapsedFilters: state => state.isCollapsedFilters,
    openedSideBars: state => state.openedSideBars,
  },
  mutations: {
    setLang(state, lang) {
      state.lang = lang
      moment.locale(lang)
      document.querySelector('html').setAttribute('lang', lang)
    },
    showErrorMessage(state, message) {
      state.error.message = message

      if (state.error.timer) clearTimeout(state.error.timer)

      state.error.timer = setTimeout(() => {
        state.error.message = null
      }, 5000)
    },
    hideErrorMessage(state) {
      state.error.message = null
      clearTimeout(state.error.timer)
      state.error.timer = null
    },
    setCollapsedSidebar(state, status) {
      localStorage.setItem('isCollapsedSidebar', JSON.stringify(status))
      state.isCollapsedSidebar = status
    },
    setCollapsedFilters(state, status) {
      state.isCollapsedFilters = status
    },
    setOpenedSideBars(state, data) {
      localStorage.setItem('openedSideBars', JSON.stringify(data))
      state.openedSideBars = data
    },
  },
  actions: {
    showErrorMessage({ commit }, message) {
      commit('showErrorMessage', message)
    },
  },
}
