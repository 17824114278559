<template>
  <div
    class="content-header"
    :class="classes"
  >
    <BQdtCard :name="qdtHeaderName" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import { BQdtCard } from '@/components/blocks/qdt'
import { QDT_HEADER_NAMES } from '@/constants'
import helpers from '@/helpers'

export default {
  name: 'BContentHeader',

  components: { BQdtCard },

  props: {
    page: {
      type: String,
      require: true,
      validator: helpers.validate.qdtHeaderNames,
    },
  },

  computed: {
    ...mapGetters('qdt', [
      'isFullscreen',
    ]),
    classes() {
      return {
        fixed: this.isFullscreen,
      }
    },
    qdtHeaderName() {
      return QDT_HEADER_NAMES[this.page]
    },
  },
}
</script>

<style scoped lang="sass">
@import "./style"
</style>
