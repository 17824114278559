<template>
  <div class="row h-100">
    <div class="col-sm-7 col-md-2 col-lg-2 col-xl-2">
      <BQdtCard
        name="comparisonCurr"
        type="top-menu-item"
      />
    </div>
    <div class="col-sm-12 col-md-8 col-lg-8 col-xl-10">
      <div class="row align-items-baseline">
        <BQdtCard
          name="comparisonDim"
          type="top-menu-item"
          class="col-md-3"
        />
        <BQdtCard
          name="comparisonMes"
          type="top-menu-item"
          class="col-md-3"
        />
        <BQdtCard
          name="comparisonVol"
          type="button"
          class="col-md-3"
        />
        <BQdtCard
          name="comparisonUn"
          type="top-menu-item"
          class="col-md-3"
        />
      </div>
    </div>
    <div class="col" />
  </div>
</template>

<script>
import { BQdtCard } from '@/components/blocks'

export default {
  name: 'ComparisonTopMenu',

  components: { BQdtCard },
}
</script>
