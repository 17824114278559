export const ICON_TYPES = {
  ACTIONS: 'actions',
  AIR_TRANSPORT: 'air-transport',
  ALERT: 'alert',
  APPROVED: 'approved',
  ANALYTIC: 'analytic',
  ARCHIVE: 'archive',
  BELL_OUTLINE: 'bell-outline',
  BOOKMARK: 'bookmark',
  BOOKMARKED: 'bookmarked',
  CALENDAR: 'calendar',
  CAR_TRANSPORT: 'car-transport',
  CARD: 'card',
  CHART: 'chart',
  CHECKMARK: 'checkmark',
  CLOCKFACE: 'clockface',
  CLOSE_BIG: 'close-big',
  CLOSE_SMALL: 'close-small',
  COPY: 'copy',
  DECLARATIONS: 'declarations',
  DESTINATION: 'destination',
  DOC_DONE: 'doc-done',
  DOWNLOAD: 'download',
  DOCUMENTS: 'documents',
  EXIT: 'exit',
  EYE: 'eye',
  FILTER: 'filter',
  GEAR: 'gear',
  GOOD: 'good',
  HEAVY_CAR: 'heavy-car',
  HOME: 'home',
  JOURNAL: 'journal',
  LIST: 'list',
  LOCK: 'lock',
  LOGIN: 'login',
  MAIL: 'mail',
  MARK_PENCIL: 'mark-pencil',
  MINUS: 'minus',
  PAYMENTS: 'payments',
  PENCIL: 'pencil',
  POLICE: 'police',
  PLUS: 'plus',
  PRINT: 'print',
  PRODUCTS: 'products',
  PRODUCTS_CONTAINER: 'products-container',
  RAILWAY_TRANSPORT: 'railway-transport',
  RESET: 'reset',
  RESTRICT: 'restrict',
  SEA_TRANSPORT: 'sea-transport',
  SEARCH: 'search',
  TEAM: 'team',
  TOGGLE_SIDE: 'toggle-side',
  TRASH: 'trash',
  UPLOAD: 'upload',
  USER: 'user',
  COMPARISON: 'comparison',
  TABLE: 'table',
  SPINNER: 'spinner',
  API_CODE: 'api-code',
  INSIDE: 'inside',
  BACK: 'back',
  ARROW_DOWN_MEDIUM: 'arrow-down-medium',
  ARROW_DOWN_SMALL_FILLED: 'arrow-down-small-filled',
  ARROW_LEFT_BIG: 'arrow-left-big',
  ARROW_LEFT_SMALL: 'arrow-left-small',
  ARROW_RIGHT_BIG: 'arrow-right-big',
  ARROW_RIGHT_SMALL: 'arrow-right-small',
  ARROW_EXPAND: 'arrow-expand',
  ARROW_EXTERNAL_LINK: 'arrow-external-link',
  ARROW_MINIMIZE: 'arrow-minimize',
  ARROW_SIMPLE_RIGHT: 'arrow-simple-right',
  ARROW_DOUBLE_RIGHT_BIG: 'arrow-double-right-big',
  ARROW_DOUBLE_RIGHT_SMALL: 'arrow-double-right-small',
  ARROW_DOUBLE_LEFT_RIGHT: 'arrow-double-left-right',
}
