<template>
  <div class="header">
    <div>
      <img
        src="@/assets/images/charts.png"
        alt=""
      >
    </div>
    <UIBadge text="bi.badge.beta">
      <h2
        class="title"
        v-html="$t('bi.open-data.title')"
      />
    </UIBadge>
    <h4
      class="sub-title mt-5"
      v-html="$t('bi.open-data.sub-title')"
    />

    <BOpenDataFullDB class="mt-auto" />
  </div>
</template>

<script>
import { BOpenDataFullDB } from '@/components/blocks/openData/BOpenDataFullDB'
import UIBadge from '@/components/ui/UIBadge/UIBadge'

export default {
  name: 'BOpenDataHeader',

  components: { UIBadge, BOpenDataFullDB },
}
</script>

<style scoped lang="sass">
@import "./style"
</style>
