<template>
  <div class="d-lg-flex pl-lg-5">
    <div class="col-lg-5">
      <BOpenDataHeader class="mb-5" />
    </div>

    <div class="col-lg-7">
      <BOpenDataContent class="mb-4" />
      <BOpenDataUpdated />
    </div>
  </div>
</template>

<script>
import {
  BOpenDataHeader,
  BOpenDataUpdated,
  BOpenDataContent,
} from '@/components/blocks'

export default {
  name: 'OpenData',

  components: {
    BOpenDataContent,
    BOpenDataUpdated,
    BOpenDataHeader,
  },
}
</script>
